import styled from "styled-components";

const Links = ({departmentData}) => {

    return(<div style={{background:"#963A3E", paddingBottom:"5%"}}>
    <h1 style={{color:"whitesmoke", padding:"2%"}}>Links and Resources:</h1>
        <div style={{display:"flex", flexDirection:"column", paddingLeft:"3%"}}>
        {departmentData.links.map((part) =>{
            return(
            <>{part.text.length>0
                ?   <>{part.url.startsWith("http")
                        ? <LinkA target="blank" href={`${part.url}`}>{part.text}</LinkA>
                        :<LinkA target="blank" href={`https://${part.url}`}>{part.text}</LinkA>}
                    </>
                :   <>{part.url.startsWith("http")
                        ? <LinkA target="blank" href={`${part.url}`}>{part.url}</LinkA>
                        :<LinkA target="blank" href={`https://${part.url}`}>{part.url}</LinkA>}
                    </>
            }</>
            ) 
        })}
        </div>

    </div>)
}

const LinkA = styled.a`
    color:whitesmoke;
`

export default Links;