const TipiBaseTile = () => {

    return (<>
    <svg 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        xmlnsXlink="http://www.w3.org/1999/xlink" 
        x="0px" 
        y="0px"
	    viewBox="0 0 90 321" 
        xmlSpace="preserve">
        <path d="M59.53,152.39c0,16.95,13.69,30.71,30.61,30.83v21.16c-16.93,0.12-30.61,13.87-30.61,30.83s13.69,30.71,30.61,30.83v58.58
            h-90v-58.58c0.07,0,0.14,0.01,0.21,0.01c17.03,0,30.83-13.81,30.83-30.83s-13.81-30.83-30.83-30.83c-0.07,0-0.14,0-0.21,0.01v-21.16
            c0.07,0,0.14,0.01,0.21,0.01c17.03,0,30.83-13.81,30.83-30.83s-13.81-30.83-30.83-30.83c-0.07,0-0.14,0-0.21,0.01v-11.01h5.28
            c0.56,0,1.06-0.35,1.26-0.88L45.01,4.9c0.16-0.44,0.78-0.44,0.94,0L84.4,109.96c0.13,0.36,0.48,0.6,0.86,0.6h4.88v11.01
            C73.21,121.68,59.53,135.44,59.53,152.39z"/>
    </svg>

    </>);
}

export default TipiBaseTile;