import { useEffect, useState } from "react";
import EditConnect from "./Forms/EditConnect";
import EditContent from "./Forms/EditContent";
import EditLinks from "./Forms/EditLinks";

const AdminDepartments = ({setCurrentFunction, setCurrentSubject}) => {
    const [loaded, setLoaded] = useState(false);
    const [departments, setDepartments] = useState();
    const [departmentToEdit, setDepartmentToEdit] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const [topic, setTopic] = useState()

    const topics = ["content", "colors", "links", "connect"];
    useEffect(() => {
        fetch('https://testingrailway-production-d638.up.railway.app/api/get_departments')
            .then(res => res.json())
            .then((data) => {
                setDepartments(data.data)
                setLoaded(true)
            })
        },[]);

    const handleSelect = (e) => {
        const departmentCopy = departments[e.target.value];
        console.log (departmentCopy +"copy");
        setDepartmentToEdit(departmentCopy);
        console.log(departmentToEdit.content)
        setIsSelected(true)
        }

    return(<>
        {loaded
            ?<select onChange={(e) => handleSelect(e)}>
                <option> select one</option>
                {departments.map((department,i) => {
                    return <option value={i}>{department.department}</option>})}
                </select>
            :<h2>loading</h2>
        }
        {isSelected && 
        <>
            <h2>{departmentToEdit.department}  field to edit </h2>
            <select onChange={(e) => setTopic(e.target.value)}>
                <option> select one</option>
                {topics.map((option) => { 
                    return <option value={option}> {option} </option>})}
            </select>
        </>
        }
        {topic === "content" && <EditContent departmentToEdit={departmentToEdit} setCurrentFunction={setCurrentFunction} setCurrentSubject={setCurrentSubject}/>}
        {topic === "links" && <EditLinks departmentToEdit={departmentToEdit} setCurrentFunction={setCurrentFunction} setCurrentSubject={setCurrentSubject} />}
        {topic === "connect" && <EditConnect departmentToEdit={departmentToEdit} setCurrentFunction={setCurrentFunction} setCurrentSubject={setCurrentSubject} />}
    </>)
}

export default AdminDepartments;