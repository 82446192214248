import { useEffect, useState } from "react";

const DeleteTeam = ({setCurrentFunction, setCurrentSubject}) => {
    const [loaded, setLoaded] = useState (false);
    const [events, setEvents] = useState ([]);
    const [eventToDelete, setEventToDelete] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const [status, setStatus] = useState("")

    //fetches list of current team
    useEffect(() => {
        fetch('https://testingrailway-production-d638.up.railway.app/api/get_events')
            .then(res => res.json())
            .then((data) => {
                console.log(data.data)
                setEvents(data.data)
                setLoaded(true)
               })
        },[]);
    
    //assigns the selected teammate to be deleted
    const handleSelect = (e) => {
        const indexOfDeletee = e.target.value;
        setEventToDelete(events[indexOfDeletee]);
        setIsSelected(true)
    }

    const handleDelete = (e) => {
        e.preventDefault();
        console.log(eventToDelete._id)
        if (window.confirm(`are you sure you want to permanently delete ${eventToDelete.eventTitle}  from the database?`)) {
            fetch(`https://testingrailway-production-d638.up.railway.app/api/delete_event/${eventToDelete.eventId}`, { method: 'DELETE' })
            .then(async response => {
                const data = await response.json();
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    setStatus(error);
                    window.alert("Error, event could not be deleted at this time")
                    return Promise.reject(error);      
                }
                window.alert('Delete event successful');
                setCurrentFunction("");
                setCurrentSubject("")
            })
        }
    }

    return (<>
        {loaded
            ?<select onChange={(e) => handleSelect(e)}>
                    <option>choose event</option>
                {events.map((event,i) => {
                    return <option value={i}>{event.eventTitle}</option>})}
                </select>
            :<h2>loading</h2>
        }
        {isSelected &&<button onClick={(handleDelete)}>Delete</button>}
        
    </>)
}


export default DeleteTeam;