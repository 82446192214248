import styled from "styled-components";
import StarBlanket from "../../Geometrics/StarBlanket";
import { motion } from "framer-motion";
import TipiBasePattern from "../../Geometrics/TipiBasePattern";
import TriangleMotifVertical from "../../Geometrics/TriangleMotifVertical";


const Transport = () => {
    const colors = ["#303038", "#6DB1C7", "#ED7268", "#E8C996", "#BE7E42", "#5B485C", "#963A3E"]

    return (
        <Wrapper>
            <Banner>
                <Logo>
                    
                    <h1 style={{fontSize:"20vw"}}>G4 </h1>
                    <h1 style={{fontSize:"15vw"}}> TRANSPORT</h1>
                </Logo>
                <div style={{position:"relative", top:"-250px", right:"-40px", mixBlendMode:"soft-light"}}>
                <motion.div
                    animate={{ rotateZ: -60 }}
                    transition={{
                        repeat: Infinity,
                        duration: 40
                    }}
                >
                <div style={{marginTop:'-180vh'}}>
                <TriangleMotifVertical />
                </div>
                </motion.div>
                </div>
            </Banner>
        </Wrapper>
    )
};

export default Transport;

const Banner = styled.div`
    max-height:100vh;
    overflow: hidden;
    background-image: url(../images/forestpath.jpg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
`

const Logo = styled.div`
    width:70%;
    position:absolute;
    z-index: 2;
    color:whitesmoke;
    text-shadow: 3px 3px 5px #5555;
`

const Top = styled.div`
    font-size: 80;

`
const Wrapper = styled.div`
    max-width: 100vw;
    height: 85vh;
    background-color: #F9F5F3;
    overflow: hidden;
`
const TextChunk = styled.div`
    max-width: 85vw;
    font-size: 14px;
    padding: 0.5%;
`
const HorizontalChunk = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5%;
    gap:30px;
    flex-wrap: wrap;
`
const LeftChunk = styled.div`
    display: flex;
    align-items: center;
    gap:5%;
    padding-left: 15%;
    flex-wrap: wrap;
`
const TopChunk = styled.div`
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
`

const TopShift = styled.div`
    @media (min-width: 768px) {
    margin-top: -80px;
    padding-right: 15%;
  }
`