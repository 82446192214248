import styled from "styled-components"
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import TriLineBottom from "./Geometrics/TriLineBottom";


const TeamMate = ({teamMember}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (


    <>

    <TeamBox onClick={handleShow}>
      <HeadShot src={teamMember.url}/>
      <Name>{teamMember.firstName} {teamMember.lastName}</Name>
      <Title>{teamMember.title}</Title>
    </TeamBox>

    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
    <Modal.Title>{teamMember.firstName} {teamMember.lastName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{teamMember.bio}</Modal.Body>
    </Modal>
    </>
    )

}

const TeamBox = styled.div`
  text-align: center;
  /* background: #303038; */
  width:250px;
  height:300px;
  margin-top: 12px;
  text-shadow: 1px 1px 2px black;
`
const HeadShot = styled.img`
  width:185px;
  margin:auto;
  margin-top: 5%;
  border: solid 4px whitesmoke;
  border-radius: 5px;
  &:hover {
    border: solid 4px #ED7268;
  }
`
const Name = styled.div`
  font-size: 20px;
  color: whitesmoke;
`
const Title = styled.div`
  color: whitesmoke;
  text-shadow: 1px 1px 2px black;
  font-size: 18px;
`
const Wrapper = styled.div`

`
const Avatar = styled.img`
    height:100px;
`
const Written = styled.div``
export default TeamMate

