const StoneyMotif2 = ({fill1="#E8C996", fill2="#ED7268", fill3="#6DB1C7", fill4="#963A3E",  height, width, transform, xStart="0", yStart="0", xEnd="1366", yEnd="768" }) => {
    const viewBoxDimensions = `${xStart} ${yStart} ${xEnd} ${yEnd}`

    return(<>
        
        
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox={viewBoxDimensions}
            height={height}
            width={width}
            xmlSpace="preserve"
            transform={transform}
            >
            
            <g id="Layer_1" fill={fill1}>
            <path class="st0" d="M1366.42,1H0.82v3.08l-0.4,314.35l0.4,0.12v30.04c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11
                v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11
                c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17
                h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64
                c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11
                v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11
                c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17h56.11c0.25,0,0.49-0.04,0.72-0.11v16.64c0,1.2,1.1,2.17,2.45,2.17
                h56.11c0.92,0,1.71-0.46,2.13-1.12c0.32-0.37,0.52-0.82,0.52-1.32v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11
                c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64
                c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11
                c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64
                c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11
                c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64
                c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11
                c1.35,0,2.45-0.97,2.45-2.17v-16.64c0.23,0.06,0.47,0.11,0.72,0.11h56.11c1.35,0,2.45-0.97,2.45-2.17v-42.57l0.4-0.13V1z"/>
            </g>
            <g id="Layer_2" fill={fill2}>
                <path class="st1" d="M1362.76,437.69h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-0.33,0-0.64,0.07-0.92,0.18v-18.52
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H535.9
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12V545.9c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45H61.72c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H2.45C1.1,438,0,439.1,0,440.45
                    v56.11C0,497.9,1.1,499,2.45,499h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76
                    c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12
                    v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.92,0,1.71-0.51,2.13-1.26
                    c0.32-0.41,0.52-0.93,0.52-1.49v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45V667.3c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-56.11C1365.2,438.79,1364.11,437.69,1362.76,437.69z"/>
            </g>
            <g id="Layer_3" fill={fill3}>
                <path class="st2" d="M1364.09,384.03h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12H833.8c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-0.33,0-0.64,0.07-0.92,0.18v-18.52
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12V576.6c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H181.6
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45H63.05c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H3.78c-1.35,0-2.45,1.1-2.45,2.45
                    v56.11c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76
                    c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12
                    v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.92,0,1.71-0.51,2.13-1.26
                    c0.32-0.41,0.52-0.93,0.52-1.49v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12H1127c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45V466c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-56.11C1366.54,385.12,1365.44,384.03,1364.09,384.03z"/>
            </g>
            <g id="Layer_4" fill={fill4}>
                <path class="st3" d="M1364.09,324.03h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12H833.8c-1.35,0-2.45,1.1-2.45,2.45v18.76
                    c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11
                    c-1.35,0-2.45,1.1-2.45,2.45v18.76c-0.23-0.07-0.47-0.12-0.72-0.12h-56.11c-0.33,0-0.64,0.07-0.92,0.18v-18.52
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12V516.6c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H181.6
                    c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45h-56.11c-0.25,0-0.49,0.05-0.72,0.12v-18.76
                    c0-1.35-1.1-2.45-2.45-2.45H63.05c-0.25,0-0.49,0.05-0.72,0.12v-18.76c0-1.35-1.1-2.45-2.45-2.45H3.78c-1.35,0-2.45,1.1-2.45,2.45
                    v56.11c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76
                    c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12
                    v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11
                    c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45
                    h56.11c0.25,0,0.49-0.05,0.72-0.12v18.76c0,1.35,1.1,2.45,2.45,2.45h56.11c0.92,0,1.71-0.51,2.13-1.26
                    c0.32-0.41,0.52-0.93,0.52-1.49v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12H1127c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76c0.23,0.07,0.47,0.12,0.72,0.12h56.11
                    c1.35,0,2.45-1.1,2.45-2.45V406c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-18.76
                    c0.23,0.07,0.47,0.12,0.72,0.12h56.11c1.35,0,2.45-1.1,2.45-2.45v-56.11C1366.54,325.12,1365.44,324.03,1364.09,324.03z"/>
            </g>

</svg>
    </>)
}

export default StoneyMotif2;