
import { useState, useEffect, useReducer } from 'react';


const EditContent = ({departmentToEdit, setCurrentFunction, setCurrentSubject}) => {
    // const [todos, dispatch] = useReducer(reducer, );
    const[add, setAdd] = useState();
    const [form, setForm] = useState(departmentToEdit.content);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [articles, setArticles] = useState([]);
    // const [body, setBody] = useState();
    const bodyTypes = ["paragraph", "list", "link"]

    useEffect(() => {
    },[loading]);

    useEffect(() =>{
        fetch('https://testingrailway-production-d638.up.railway.app/api/get_articles')
        .then(res => res.json())
        .then((data) => {
            setArticles(data.data)
        })
    },[])


    const setField = ( i, field, value, contentType, j, k) => {
        const newForm = [...form];
        if (field === "header"){
            newForm[i][field]=value;
            setForm(newForm);
        }
        if (field === "body"){
            setBody(i, value, contentType, j, k, newForm)
        }
    }    

    const setBody = ( i, value, contentType, j, k, newForm) => {
        const newBody =[...form[i]["body"]];
        console.log(newBody,'noassign');
        if (contentType === "paragraph"){
            newBody[j].text=value;
        } else if(contentType === "listItem"){
            const newList = [...newBody[j].list]
            newList[k] = value;
            newBody[j].list = newList;
        } else {
            newBody[j].link[contentType]=value
        }
        newForm[i]["body"]=newBody;
        setForm(newForm);
        
    }

    const deleteSection = (index) => {
        setLoading(true)
        setForm([
            ...form.slice(0, index),
            ...form.slice(index + 1)
        ]);
        setLoading(false)  
    }

    const handleAddSection = () => {
        const newSection = {header:"", body:[{type:"paragraph",text:""}]}
        setForm([...form, newSection])
    }

    const handleAddPart = (newType, i) => {
        setLoading(true)
        const newForm = [...form];
        const newBody =[...form[i]["body"]];
        let newPart;
        if(newType==="paragraph"){
            newPart = { type:newType , text:"" }
        }
        if(newType==="list"){
            newPart = { type:newType , list:["","","","","","","","",""] }
        }
        if(newType==="link"){
            newPart = { type:newType, link:{ url:"", articleID:"", displayText:""} }
        }
        
        
        newForm[i]['body'] = ([...newBody, newPart]);
        setForm(newForm);
        setLoading(false)
    }

    const handleDeletePart = (i,j) => {
        console.log('hello',i,j)
        setLoading(true)
        const newForm = [...form];
        const newBody =[...form[i]["body"]];
        console.log(newBody)
        newBody.splice(j, 1);
        // newBody.slice(j + 1);
        console.log(newBody)
        newForm[i].body = newBody;
        setForm(newForm)
        console.log(form)
        // setForm([
        //     ...form[i].body.slice(0, j),
        //     ...form[i].body.slice(j + 1)
        // ]);
        setLoading(false)  
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm(`are you sure you want to update ${departmentToEdit.department}  in the database?`)) {
            
            const expressRes = await fetch(`https://testingrailway-production-d638.up.railway.app/api/update_department/${departmentToEdit.department}`, {
                method: "PATCH",
                body: JSON.stringify({
                    "_id":departmentToEdit._id,
                    "colors":departmentToEdit.colors,
                    "content":form,
                    "department":departmentToEdit.department,
                    "links":departmentToEdit.links,
                    "socialmedia":departmentToEdit.socialmedia,
                    "_id":departmentToEdit.id
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                })
            const data = await expressRes.json();
                if (data.status === 200) {
                    window.alert("department content uploaded");
                    setForm({});
                    setCurrentFunction("");
                    setCurrentSubject("");
                } else {
                    window.alert("Could not update content at this time :(")
                }
        }
    }

    

    return(<>
        <h1>{departmentToEdit.department}edit content</h1>
        {!loading && <>
        {form.map((section, i) => {
            return(<>
                <div style={{border:"2px solid black", borderRadius:"10px", margin:"1%", }}>
                    <h1>Section {i+1}</h1>
                    {!editing &&
                        <button onClick={(e) => deleteSection(i)}> Delete Section </button>
                    }

                    <button onClick={(e)=> setEditing(!editing)}>
                        edit
                    </button>
                    <h3>HEADER:</h3>
                    {!editing
                        ?<p>{section.header}</p>
                        :<textarea style={{width:"400px", height:"100px"}} onChange={(e)=> setField( i,'header', e.target.value)} defaultValue={section.header}></textarea>
                    }
                    <h3>body:</h3>
                        {section.body.map((portion, j) => {
                            return(<>
                                <div style={{border:"3px orange solid", borderRadius:"5px", padding:"12px"}}>

                                    <h4>part: {j+1}</h4>
                                    {portion.type === "paragraph" && <>
                                        <h3>PARAGRAPH</h3>
                                        {!editing
                                            ?<p>{portion.text}</p>
                                            :<textarea style={{width:"600px", height:"200px"}} onChange={(e)=> setField(i,'body', e.target.value, 'paragraph', j)}>{portion.text}</textarea>
                                        }
                                    </>}
                                    {portion.type === "link" && <>
                                        <h3>LINK</h3>
                                        {!editing
                                            ?<>
                                                <p>{portion.link.url}</p>
                                                <p>{portion.link.displaytext}</p>
                                            </>
                                            :<div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", width:"70%"}}> 
                                                <div style={{display:"flex", justifyContent:"flex-end"}}>
                                                    <h5>Display Text:</h5>
                                                    <textarea style={{width:"400px", height:"30px"}} onChange={(e)=> setField(i,'body', e.target.value, 'displayText', j)}>{portion.link.displaytext}</textarea>
                                                </div>
                                                    <div style={{display:"flex", marginLeft:"9%",paddingTop:"3%"}}>
                                                        <h5>Select Article</h5>
                                                        <select  onChange={(e)=> setField(i,'body', e.target.value, 'url', j)}>
                                                            <option>pick one</option>
                                                            {articles.map((article) => {
                                                                return(<option value={article.url}>{article.title}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"flex-end"}}>
                                                        <h5>Enter Link url:</h5>
                                                        <textarea style={{width:"400px", height:"30px"}} onChange={(e)=> setField(i,'body', e.target.value, 'url', j)}>{portion.link.url}</textarea>
                                                    </div>

                                                

                                            </div>
                                        }
                                    </>}
                                    {portion.type === "list" && <>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <h3>LIST</h3>
                                            {portion.list.map((item, k) =>{
                                                return(<>
                                                    {!editing
                                                        ?<li>{item}</li>
                                                        :<textarea style={{width:"400px", height:"60px"}} onChange={(e)=> setField(i,'body', e.target.value, 'listItem', j, k)}>{item}</textarea>
                                                    }
                                                </>)    
                                            })}
                                        </div>
                                    </>}
                                    <button onClick={(e) => handleDeletePart(i,j)}>delete part</button>
                                </div>
                                
                            </>)
                        })}

                    <h2>add part</h2>
                    {bodyTypes.map((type) =>{
                        return<button onClick={(e)=> handleAddPart(type, i)}>{type}</button>
                                    })}
                </div>
            </>)
        })}
        <button onClick={(e) => handleAddSection(e)}>Add Section</button>
        <button onClick={(e) => handleSubmit(e)}>submit</button>
        </>}
    </>)
}

export default EditContent;