import { useState, useEffect } from 'react'


const EditConnect = ({departmentToEdit, setCurrentFunction, setCurrentSubject}) => {

    const [preview, setPreview] = useState();
    const [form, setForm] = useState(departmentToEdit.socialmedia);

    const setField = (i, value ) => {
        const newForm = [...form];
        newForm[i]=value;
        setForm(newForm);
        console.log(newForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm(`are you sure you want to update ${departmentToEdit.department}  in the database?`)) {
            
            const expressRes = await fetch(`https://testingrailway-production-d638.up.railway.app/api/update_department/${departmentToEdit.department}`, {
                method: "PATCH",
                body: JSON.stringify({
                    "_id":departmentToEdit._id,
                    "colors":departmentToEdit.colors,
                    "content":departmentToEdit.content,
                    "department":departmentToEdit.department,
                    "links":departmentToEdit.links,
                    "socialmedia":form,
                    "_id":departmentToEdit.id
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                })
            const data = await expressRes.json();
                if (data.status === 200) {
                    window.alert("department social links uploaded");
                    setForm({});
                    setCurrentFunction("");
                    setCurrentSubject("");
                } else {
                    window.alert("Could not update content at this time :(")
                }
        }
    }


    return(<>
    <h3> SOCIAL MEDIA LINKS</h3>
        {departmentToEdit.socialmedia.map((link, i) => {
            return(<>
                <div style={{display:"flex", paddingBottom:"3%"}}>
                <h6> {i+1}:</h6>
                <textarea style={{width:"400px", height:"30px"}} onChange={(e)=> setField(i, e.target.value)}>{link}</textarea>
                </div>
            </>)
        })}
    
    <button onClick={(e) => handleSubmit(e)}>submit</button>
    </>)
}

export default EditConnect;