import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel'
import { useState, useEffect } from 'react'
import uuid from 'react-uuid';

const EditLinks = ({departmentToEdit, setCurrentFunction, setCurrentSubject}) => {

    const [preview, setPreview] = useState();
    const [form, setForm] = useState(departmentToEdit.links);

    const setField = (i, field, value ) => {
        const newForm = [...form];
        newForm[i][field]=value;
        console.log(newForm)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm(`are you sure you want to update ${departmentToEdit.department}  in the database?`)) {
            
            const expressRes = await fetch(`https://testingrailway-production-d638.up.railway.app/api/update_department/${departmentToEdit.department}`, {
                method: "PATCH",
                body: JSON.stringify({
                    "_id":departmentToEdit._id,
                    "colors":departmentToEdit.colors,
                    "content":departmentToEdit.content,
                    "department":departmentToEdit.department,
                    "links":form,
                    "socialmedia":departmentToEdit.socialmedia,
                    "_id":departmentToEdit.id
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                })
            const data = await expressRes.json();
                if (data.status === 200) {
                    window.alert("department links uploaded");
                    setForm({});
                    setCurrentFunction("");
                    setCurrentSubject("");
                } else {
                    window.alert("Could not update content at this time :(")
                }
        }
    }


    return(<>
    <h3> LINKS</h3>
        {departmentToEdit.links.map((link, i) => {
            return(<>
                
                <h5 style={{}}>link {i + 1}</h5>
                
                <div style={{display:"flex"}}>
                <h6>Text:</h6>
                <textarea style={{width:"400px", height:"30px"}} onChange={(e)=> setField(i,'text', e.target.value)}>{link.text}</textarea>
                </div>
                <div style={{display:"flex"}}>
                <h6>Link:</h6>
                <textarea style={{width:"400px", height:"30px"}} onChange={(e)=> setField(i,'url', e.target.value)}>{link.url}</textarea>
                </div>
            </>)
        })}
    
    <button onClick={(e) => handleSubmit(e)}>submit</button>
    </>)
}

export default EditLinks;