const LogoSVG = ({fill, width, height}) => {
    return(
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 470 311.53" 
            width={width}
            height={height}
            // style="enable-background:new 0 0 470 311.53;" 
            xmlSpace="preserve">

<g fill={fill}>
	<g>
		<path d="M369.87,177.94l-2.16-2.23c-0.12-0.12-0.31-0.14-0.44-0.04l-37.42,27.58c-0.17,0.13-0.18,0.38-0.02,0.53l33.29,29.38
			c0.18,0.16,0.47,0.08,0.55-0.15l4.78-15.31c0.04-0.12,0.01-0.25-0.08-0.34l-6.6-6.6c-0.14-0.14-0.13-0.37,0.02-0.5l7.97-6.78
			c0.08-0.06,0.12-0.16,0.12-0.26v-24.45c0-0.3,0.37-0.45,0.58-0.24L432,247 M276,154l-0.5,17.34c0,0.1-0.05,0.2-0.14,0.26
			l-3.73,2.8c-0.09,0.06-0.14,0.16-0.14,0.27v14.7c0,0.08-0.03,0.17-0.09,0.23l-9.74,10.72c-0.1,0.11-0.12,0.27-0.04,0.4
			l17.75,29.58c0.07,0.12,0.06,0.28-0.03,0.39l-12.61,15.52c-0.13,0.16-0.36,0.17-0.5,0.02L222.5,201.5l0.03-0.03l50.74-49.74
			c0.13-0.13,0.33-0.13,0.47-0.01l55.76,51.78 M194.98,170.24l27.55,31.23L223,202 M194.84,170.08 M175.2,180.23 M149,161
			l-0.05,0.07l21.51,22.42l-0.5-0.55 M206.71,199.17l-1.17,21.52c-0.02,0.3-0.38,0.43-0.59,0.21l-33.89-36.76l-0.61-0.66l4.74-3.26
			l17.55-12.06c0.14-0.1,0.33-0.07,0.45,0.05l1.64,1.86l0.14,0.16l-1.47,18.08c-0.01,0.11,0.04,0.22,0.13,0.29l12.94,10.28
			C206.67,198.96,206.72,199.07,206.71,199.17z M148.78,161.3l-2.23,3.03c-0.03,0.05-0.06,0.1-0.06,0.16l-1.97,16.88
			c-0.01,0.08-0.05,0.16-0.11,0.22l-15.63,13.68c-0.15,0.13-0.15,0.36-0.02,0.49l5.57,5.57c0.11,0.11,0.13,0.27,0.06,0.4
			l-6.81,12.65c-0.04,0.08-0.12,0.14-0.21,0.17l-22.67,5.91c-0.12,0.03-0.21,0.12-0.24,0.24l-7.19,25.57
			c-0.04,0.15-0.17,0.25-0.33,0.25H48.54c-0.31,0-0.46-0.38-0.23-0.59l97.44-87.69c0.14-0.12,0.34-0.11,0.47,0.02l2.53,2.63
			C148.87,160.98,148.88,161.17,148.78,161.3z"/>
		<path class="st0" d="M432,249c-0.55,0-1.09-0.22-1.49-0.66l-58.64-65.23v20.12c0,0.68-0.3,1.33-0.82,1.78l-6.61,5.62l5.32,5.32
			c0.62,0.62,0.84,1.52,0.58,2.36l-4.78,15.3c-0.23,0.76-0.83,1.34-1.6,1.55c-0.77,0.21-1.58,0.03-2.18-0.5l-33.29-29.38
			c-0.05-0.05-0.11-0.1-0.16-0.15c-0.07-0.05-0.13-0.1-0.2-0.16l-50.26-46.67l-0.37,13.1c-0.02,0.72-0.36,1.38-0.94,1.81l-3.06,2.3
			v13.87c0,0.58-0.21,1.14-0.61,1.57l-8.92,9.81l17.11,28.52c0.5,0.84,0.43,1.91-0.19,2.67l-12.62,15.53
			c-0.41,0.51-1.04,0.83-1.7,0.86c-0.66,0.04-1.32-0.22-1.78-0.7l-43.74-44.73c-0.12-0.13-0.23-0.27-0.31-0.41l-24.17-27.39
			l-1.01,12.51l12.25,9.73c0.59,0.46,0.92,1.2,0.88,1.96v0l-1.17,21.52c-0.05,0.94-0.65,1.75-1.54,2.07
			c-0.89,0.32-1.87,0.08-2.51-0.62L169,184.86l-19.85-20.69l-0.73,0.99l-1.92,16.44c-0.07,0.58-0.34,1.1-0.78,1.49l-14.29,12.51
			l4.31,4.31c0.73,0.73,0.9,1.84,0.41,2.75l-6.81,12.66c-0.31,0.57-0.85,0.99-1.47,1.16l-21.74,5.67l-6.93,24.65
			c-0.28,1-1.21,1.71-2.25,1.71H48.54c-0.98,0-1.83-0.59-2.18-1.5s-0.11-1.92,0.62-2.58l97.44-87.69c0.94-0.85,2.37-0.8,3.25,0.12
			l2.44,2.54c0.01,0.01,0.02,0.01,0.03,0.02c0.23,0.17,0.42,0.37,0.56,0.6l20.03,20.88l20.89-14.36c0.98-0.67,2.3-0.5,3.08,0.38
			l1.79,2.02l26.16,29.65l49.23-48.27c0.89-0.88,2.31-0.9,3.23-0.04l54.55,50.65l36.43-26.84c0.94-0.69,2.26-0.58,3.06,0.25
			l2.17,2.23c0.1,0.1,0.18,0.2,0.25,0.32c0.12,0.08,0.23,0.18,0.33,0.28l61.6,68.52c0.74,0.82,0.67,2.09-0.15,2.82
			C432.96,248.83,432.48,249,432,249z M265.19,244.94C265.19,244.94,265.19,244.95,265.19,244.94L265.19,244.94z M52.87,244.5h42.8
			l6.85-24.35c0.23-0.8,0.87-1.42,1.66-1.63l21.97-5.73l5.88-10.92l-4.69-4.69c-0.46-0.46-0.71-1.09-0.68-1.73s0.31-1.26,0.8-1.68
			l15.15-13.25l1.9-16.25c0.05-0.4,0.2-0.78,0.43-1.11l1.4-1.91l-0.44-0.46L52.87,244.5z M225.33,201.53l41.04,41.97l10.7-13.17
			l-17.15-28.58c-0.53-0.89-0.43-2,0.27-2.77l9.32-10.25v-14.06c0-0.74,0.35-1.44,0.95-1.88l3.08-2.31l0.45-15.8l-0.44-0.41
			L225.33,201.53z M361.75,232.4C361.75,232.41,361.75,232.41,361.75,232.4L361.75,232.4z M332.68,203.64l29.83,26.32l3.72-11.9
			l-5.88-5.88c-0.46-0.46-0.71-1.09-0.68-1.74c0.03-0.65,0.32-1.26,0.82-1.69l7.39-6.29v-23.68c0-0.01,0-0.02,0-0.02l-0.59-0.61
			L332.68,203.64z M366.53,217.1c0,0,0,0.01,0,0.01L366.53,217.1z M173.5,183.83l30.26,32.83l0.91-16.74l-12.27-9.75
			c-0.61-0.48-0.94-1.24-0.88-2.01l1.4-17.24l-0.24-0.28L173.5,183.83z M126.85,212.6C126.85,212.6,126.85,212.6,126.85,212.6
			L126.85,212.6z M205.33,200.45c0,0,0.01,0.01,0.02,0.01L205.33,200.45z M206.71,199.17L206.71,199.17L206.71,199.17z
			 M194.88,187.04L194.88,187.04C194.89,187.05,194.88,187.04,194.88,187.04z M272.85,175.98l-0.02,0.01
			C272.84,175.99,272.85,175.99,272.85,175.98z M274.17,170c0,0-0.01,0.01-0.02,0.01L274.17,170z M272.38,153.19
			C272.38,153.19,272.38,153.19,272.38,153.19L272.38,153.19z"/>
	</g>
	<g>
		<path d="M215.22,276.69c0-2.52-2.4-2.79-4.95-3.12c-2.91-0.39-6.06-1.41-6.06-6.15c0-5.22,4.2-6.36,8.31-6.36
			c1.98,0,3.81,0.24,5.55,0.42v3.36c-1.5-0.21-3.6-0.51-5.49-0.51c-3,0-4.5,0.93-4.5,3c0,2.25,2.01,2.58,4.29,2.94
			c3.15,0.51,6.72,1.02,6.72,6.27c0,4.77-3.36,6.84-8.22,6.84c-2.4,0-4.53-0.27-6.39-0.63v-3.39c2.25,0.42,4.26,0.75,6.09,0.75
			C213.6,280.11,215.22,278.97,215.22,276.69z"/>
		<path d="M228.18,267.69v15.33h-3.78v-21.6h4.11l10.17,15.69v-15.69h3.78v21.6h-4.08L228.18,267.69z"/>
		<path d="M265.05,264.69h-7.14v18.33h-3.78v-18.33h-7.14v-3.27h18.06V264.69z"/>
		<path d="M285.75,264.69h-7.14v18.33h-3.78v-18.33h-7.14v-3.27h18.06V264.69z"/>
		<path d="M298.83,261.06c1.8,0,3.66,0.18,5.76,0.72v3.45c-1.32-0.42-3.42-0.9-5.07-0.9c-4.2,0-6.78,2.1-6.78,7.77
			c0,6.03,3.03,8.01,6.57,8.01c1.68,0,3.66-0.39,5.34-1.05v3.39c-1.89,0.66-4.14,0.93-5.79,0.93c-6.75,0-9.99-4.17-9.99-11.19
			C288.87,265.35,292.29,261.06,298.83,261.06z"/>
	</g>
	<path class="st1" d="M205,82l31.05-0.41c3.56-0.05,7.41,3.35,7.45,6.91v34.99c0,2.55-1.75,4.76-4.23,5.37
		c-5.38,1.32-16.69,3.14-38.27,3.14c-46.57-0.26-54.34-19.55-53.86-50.3c0.98-44.02,38.66-49.95,67.77-47.7
		c0,0,14.19,1.37,25.92,9.31c0.88,0.6,1.01,1.84,0.25,2.59l-3.69,3.69c-1.1,1.1-2.81,1.33-4.16,0.56
		c-4.4-2.51-14.43-7.65-22.74-7.65c-17.9,0.63-23.12-0.32-35,7c-7.87,7.16-11.99,18.12-12,28.72c0,0,0,13.85,0,13.85
		c0.01,13.26,7.37,24.35,19,28.43c12.33,4.09,28.89,3.35,43.48,0.45c1.46-0.25,2.52-1.51,2.52-3l0-25.45h-24
		C199.94,90.61,201.02,83.53,205,82z"/>
	<path class="st2" d="M283.83,101.5h25.55c2.13,0,3.86-1.71,3.89-3.84l0.22-16.56c0.01-1.02,0.43-2,1.15-2.73l2.97-2.97
		c1.22-1.22,2.87-1.9,4.6-1.9h5.88c0.79,0,1.43,0.64,1.43,1.43v26.57h18.31c0.38,0,0.69,0.31,0.69,0.69v6.43
		c0,1.04-0.84,1.88-1.88,1.88h-17.7l0.54,18.43c0.03,0.86-0.67,1.57-1.53,1.57H313.5v-20l-40.61,0.77c-5.48,0.1-9-5.79-6.31-10.56
		l34.74-58.23c0.73-1.23,2.05-1.98,3.48-1.98h8.53c1.14,0,1.86,1.23,1.29,2.23l-31.98,56.75
		C282.13,100.38,282.79,101.5,283.83,101.5z"/>
</g>
        </svg>

    )
}

export default LogoSVG