import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons' 
import { GrMail } from 'react-icons/gr'

const Connect = ({departmentId, departmentData}) => {
    
    return(<>
    
        <div style={{display:'flex', justifyContent:'space-around', alignItems:"center", padding:'3%', marginTop:'-1px', background:"#303038", color:"white"}}>
        <h1>Connect with us:</h1>
            <Link to={`/connect/${departmentId}`} style={{ height: 150, width: 150, display:"flex", justifyContent:"center", paddingTop:"2.5%", fontSize:"80px", color:"#303038", borderRadius:"100%", background:"#E8C996" }} index={2}><GrMail/></Link>
        {departmentData.socialmedia.map((link) => {
            return link.length > 0 && <SocialIcon url={link} style={{ height: 150, width: 150 }}/>
        })}
        
        </div>
    </>)
}

export default Connect