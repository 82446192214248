import { Section, SectionLeft, SectionRight } from "../AnimationPresets/AnimationPresets";
import StoneyMotif2 from "../Geometrics/StoneyMotif2";
import styled from "styled-components";
import { Link } from "react-router-dom";

const ContentGeoLayout = ({departmentData}) => {
    const colors = ["#303038", "#6DB1C7", "#ED7268", "#E8C996", "#BE7E42", "#5B485C", "#963A3E"]

    return(<>
        <SectionLeft>
                <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} fill1={colors[7]} fill2={colors[1]} fill3={colors[0]} fill4={colors[2]}/>
            </SectionLeft>

        {departmentData.content.map((depContent, i)=>{
                    return(<>
                        <Section>
                            <HorizontalChunk>
                                <Header>{depContent.header}</Header>
                                <TextChunk>
                                {depContent.body.map((part) =>{
                                    return(<>
                                    {part.type === "paragraph"&&

                                            <p>
                                            {part.text}
                                            </p>
                                    }
                                    {part.type === "list"&&
                                    <ul>
                                        {part.list.map((item) =>{
                                            return<> {item.length > 0 && <li>{item}</li>}</>
                                            })
                                        }
                                    </ul>
                                    }
                                    {part.type === "link" &&
                                        <>{part.link.url.startsWith("http")
                                            ?<a target="blank" href={`${part.link.url}`}>{part.link.displayText}</a>
                                            // :<Link to={`/article/thisarticle`}>{part.link.displayText}</Link>
                                            :<a target="blank" href={`https://${part.link.url}`}>{part.link.displayText}</a>
                                        }</>
                                    }
                                    </>)
                                        })}
                                    </TextChunk>
                            </HorizontalChunk>
                    </Section>
                    {i === 0 &&
                    <SectionLeft>
                        <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(1,-1)"} fill1={colors[7]} fill2={colors[1]} fill3={colors[0]} fill4={colors[2]}/>
                        <div style={{marginTop:"-4px"}}>
                            <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} fill1={colors[7]} fill2={colors[1]} fill3={colors[0]} fill4={colors[2]}/>
                        </div>
                    </SectionLeft>}
                    { i === 1 &&           
                    <SectionRight>
                    <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(-1,-1)"} fill1={colors[0]} fill2={colors[1]} fill3={colors[4]} fill4={colors[6]}/>
                    <div style={{marginTop:"-2px"}}>
                        <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(-1,1)"} fill1={colors[0]} fill2={colors[1]} fill3={colors[4]} fill4={colors[6]}/>
                    </div>
                </SectionRight>
                    }
                    { i === 2 &&           
                    <SectionRight>
                    <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(-1,-1)"} fill1={colors[0]} fill2={colors[1]} fill3={colors[4]} fill4={colors[6]}/>
                    <div style={{marginTop:"-2px"}}>
                        <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(-1,1)"} fill1={colors[0]} fill2={colors[1]} fill3={colors[4]} fill4={colors[6]}/>
                    </div>
                </SectionRight>
                    }
                    { i === 3 &&
                        <SectionLeft>
                            <StoneyMotif2 xStart={"500"} yStart={"475"}  yEnd={"260"} transform={"scale(1,-1)"} fill1={colors[0]} fill2={colors[1]} fill3={colors[6]} fill4={colors[5]}/>
                            <div style={{marginTop:"-5px"}}>
                                <StoneyMotif2 xStart={"500"} yStart={"475"}  yEnd={"260"} fill1={colors[0]} fill2={colors[1]} fill3={colors[6]} fill4={colors[5]}/>
                            </div>
                        </SectionLeft>
                    }
                    {i === 4 && 
                                <SectionRight>
                                <StoneyMotif2 xStart={"500"} yStart={"475"}  yEnd={"260"} transform={"scale(-1,-1)"} fill1={colors[5]} fill2={colors[2]} fill3={colors[0]} fill4={colors[1]}/>
                                <div style={{marginTop:"-5px"}}>
                                <StoneyMotif2 xStart={"500"} yStart={"475"}  yEnd={"260"} transform={"scale(-1,1)"} fill1={colors[5]} fill2={colors[2]} fill3={colors[0]} fill4={colors[1]}/>
                                </div>
                            </SectionRight>
                    }

                </>)
        })}
            <SectionLeft>
                <StoneyMotif2 xStart={"545"} yStart={"500"} yEnd={"250"} xEnd={"900"} width={"100vw"} transform={"scale(1,-1)"} fill1={colors[1]} fill2={colors[2]} fill3={colors[5]} fill4={colors[0]}/>
            </SectionLeft>
    </>)
};

const Header = styled.h1 `
    /* width:fit-content; */
    @media (min-width: 768px) {
        max-width:35%;
        font-size: 55px;
    }
`

const TextChunk = styled.div`
    display:flex;
    flex-direction: column;
    width: 90%;
    font-size: 14px;
    padding: 0.5%;
    @media (min-width: 768px) {
        width:60%;
        font-size: 25px;
    }
`
const HorizontalChunk = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5%;
    gap:30px;
    flex-wrap: wrap;
`

export default ContentGeoLayout;