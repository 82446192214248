import styled from "styled-components";
import Arrow from "../../Geometrics/Arrow";
import StoneyMotif2 from "../../Geometrics/StoneyMotif2";
import LogoRange from "../../Geometrics/LogoRange";
import { Section, SectionLeft, SectionRight } from "../../AnimationPresets/AnimationPresets";
import StarBlanket from "../../Geometrics/StarBlanket";
import { motion } from "framer-motion";
import StoneyBig from "../../Geometrics/StoneyBig";
import StarBlanketStokeFill from "../../Geometrics/StarBlanketStrokeFill";
import TriangleMotifVertical from "../../Geometrics/TriangleMotifVertical";


const Commitments = () => {
    const colors = ["#303038", "#6DB1C7", "#ED7268", "#E8C996", "#BE7E42", "#5B485C", "#963A3E"]

    return (
        <Wrapper>
            <Banner>
                <div style={{position:"absolute", zIndex:"10"}}>

                <motion.div
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    transition={{ duration: 0.8 }}
                    >

                    <Logo>
                        
                        <h1 style={{fontSize:"60px", marginTop:"10%", color:"white"}}>REACHING HOME </h1>
                        <h1 style={{fontSize:"50px"}}>Canada's Homelessness Strategy</h1>
                    </Logo>
                </motion.div>
                <div style={{position:"relative", top:"-1450px", left:"850px", opacity:"0.7"}}>
                    <motion.div
                        animate={{ rotateX: 65 }}
                        transition={{
                            repeatType: "reverse",
                            repeat: Infinity,
                            duration: 40
                        }}
                        >
                        {/* <StarBlanketStokeFill height={"46vh"}/> */}
                        <TriangleMotifVertical transform={"rotate(-110) "} height={"3400px"} fill1={colors[3]} fill2={colors[1]} fill3={colors[4]} fill4={colors[2]}/>
                    </motion.div>
                </div>
                </div>
                <Colorizer/>
            </Banner>
        </Wrapper>
    )
};

export default Commitments;

const Banner = styled.div`
    height:85vh;
    position:relative;
    overflow: hidden;
    background-image: url(../images/oldfence.jpg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
`

const Logo = styled.div`
    width:85vw;
    position:absolute;
    z-index: 2;
    color:white;
    text-shadow: 3px 3px 10px #555;
    padding-left: 3%;
`

const Top = styled.div`
    font-size: 80;

`
const Wrapper = styled.div`
    max-width: 100vw;
    background-color: "#ED7268";
    overflow: hidden;
`
const TextChunk = styled.div`
    max-width: 85vw;
    font-size: 14px;
    padding: 0.5%;
`

const HorizontalChunk = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5%;
    gap:30px;
    flex-wrap: wrap;
    font-size: 35vw;
`
const LeftChunk = styled.div`
    display: flex;
    align-items: flex-start;
    width:95%;
    gap:5%;
    padding-left:5%;
    flex-wrap: wrap;
`
const TopChunk = styled.div`
    display: flex;
    padding: 1%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
`

const Colorizer = styled.div`
    background-color:#ED7268;
    height:100%;
    width:100%;
    opacity: 0.6;
    position: absolute;
    z-index:3;
`

const TopShift = styled.div`
    @media (min-width: 768px) {
    margin-top: -70px;
    padding-right: 15%;
  }
`