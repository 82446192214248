import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import EditEventPhoto from "./EditEventPhoto";
import EditEventWritten from "./EditEventWritten";


const EditEvent = ({setCurrentFunction, setCurrentSubject}) => {
    const [loaded, setLoaded] = useState (false);
    const [event, setEvent] = useState ([]);
    const [eventToEdit, setEventToEdit] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const [currentEdit, setCurrentEdit] = useState("");
    
    //fetches list of current event
    useEffect(() => {
        fetch('https://testingrailway-production-d638.up.railway.app/api/get_events')
            .then(res => res.json())
            .then((data) => {
                console.log(data.data)
                setEvent(data.data)
                setLoaded(true)
            })
        },[]);
        
    const handleSelect = (e) => {
        const indexOfeditee = e.target.value;
        setEventToEdit(event[indexOfeditee]);
        setIsSelected(true)
    }

    return (<>
        {loaded
            ?<select onChange={(e) => handleSelect(e)}>
                <option >choose event</option>
                {event.map((event,i) => {
                    return <option value={i}>{event.eventTitle}</option>})}
            </select>
            :<h2>loading</h2>
        }
        {isSelected && [<>
            <button onClick={(e)=>setCurrentEdit("written")}>written</button>
            <button onClick={(e)=>setCurrentEdit("photo")}>photo</button>
            {currentEdit === "written" && <EditEventWritten eventToEdit={eventToEdit} setEventToEdit={setEventToEdit} setCurrentFunction={setCurrentFunction} setCurrentSubject={setCurrentSubject} setCurrentEdit={setCurrentEdit}/>}
            {currentEdit === "photo" && <EditEventPhoto eventToEdit={eventToEdit} setEventToEdit={setEventToEdit} setCurrentFunction={setCurrentFunction} setCurrentSubject={setCurrentSubject} setCurrentEdit={setCurrentEdit}/>}
        </>]}
    </>)
}

const PhotoSection = styled.div`
    display:flex;
`

const PreviewImg = styled.img`
    height:300px;
`
const CurrentPhoto = styled.img`
    height:300px;
`

export default EditEvent;