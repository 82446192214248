import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel'
import { useState, useEffect } from 'react'
import uuid from 'react-uuid';

const AdminArticle = ({currentSubject, setCurrentSubject, setCurrentFunction}) => {
    const [form, setForm] = useState({});
    const [preview, setPreview] = useState();

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const fileData = new FormData();
        fileData.append("file", form.selectedFile);
        fileData.append("upload_preset", "G4SNTTC");
        const cloudinaryRes = await fetch(
            "https://api.cloudinary.com/v1_1/dwczrztj8/image/upload",
            {
                method: "POST",
                body: fileData,
            })
        const cloudinaryData = await cloudinaryRes.json();

        const expressRes = await fetch("https://testingrailway-production-d638.up.railway.app/api/add_article", {
            method: "POST",
            body: JSON.stringify({
                url: cloudinaryData.secure_url,
                title: form.title,
                articleId: uuid()
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const data = await expressRes.json();
        if (data.status === 201) {
            window.alert("New article created");
            setForm({});
            setCurrentSubject("");
            setCurrentFunction("");
        } else {
            window.alert("Could not create new article at this time :(")
        }
        
    };

    return(<>
        <h1>Add Article</h1>
        <Form onSubmit={handleSubmit}>
        <FloatingLabel
            required
            controlId="floatingInput"
            label="Article Title"
            className="mb-3"
            >
            <Form.Control type="text" placeholder="Enter Article Title" onChange={(e)=> setField('title', e.target.value) }/>                
        </FloatingLabel>
        <Form.Group controlId="formFile" className="mb-3" class="form-control-file border">
                    <Form.Label>Upload Article PDF</Form.Label>
                    <Form.Control type="file" onChange={(e) => setField('selectedFile',  e.target.files[0])} />
                    {/* <Form.Control.Feedback type='invalid'> { errors.selectedFile } </Form.Control.Feedback> */}
                </Form.Group>
                <br/>
        <Button type="submit">Submit</Button>
        </Form>
    </>)
}

export default AdminArticle;
