const LogoRange = ({fill, width, height, transform}) => {
    return(
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
            viewBox="0 0 388.8 100.37"
            width={width}
            height={height}
			transform={transform}
            // style="enable-background:new 0 0 470 311.53;" 
            xmlSpace="preserve">

<g fill={fill}>
	<path d="M324.17,28.81L322,26.59c-0.12-0.12-0.31-0.14-0.44-0.04l-37.42,27.58c-0.17,0.13-0.18,0.38-0.02,0.53l33.29,29.38
		c0.18,0.16,0.47,0.08,0.55-0.15l4.78-15.31c0.04-0.12,0.01-0.25-0.08-0.34l-6.6-6.6c-0.14-0.14-0.13-0.37,0.02-0.5l7.97-6.78
		c0.08-0.06,0.12-0.16,0.12-0.26V29.65c0-0.3,0.37-0.45,0.58-0.24l61.55,68.46 M230.3,4.87l-0.5,17.34c0,0.1-0.05,0.2-0.14,0.26
		l-3.73,2.8c-0.09,0.06-0.14,0.16-0.14,0.27v14.7c0,0.08-0.03,0.17-0.09,0.23l-9.74,10.72c-0.1,0.11-0.12,0.27-0.04,0.4l17.75,29.58
		c0.07,0.12,0.06,0.28-0.03,0.39l-12.61,15.52c-0.13,0.16-0.36,0.17-0.5,0.02L176.8,52.37l0.03-0.03L227.57,2.6
		c0.13-0.13,0.33-0.13,0.47-0.01l55.76,51.78 M149.28,21.11l27.55,31.23l0.47,0.53 M149.14,20.95 M129.5,31.1 M103.3,11.87
		l-0.05,0.07l21.51,22.42l-0.5-0.55 M161.01,50.05l-1.17,21.52c-0.02,0.3-0.38,0.43-0.59,0.21l-33.89-36.76l-0.61-0.66l4.74-3.26
		l17.55-12.06c0.14-0.1,0.33-0.07,0.45,0.05l1.64,1.86l0.14,0.16l-1.47,18.08c-0.01,0.11,0.04,0.22,0.13,0.29l12.94,10.28
		C160.97,49.83,161.02,49.94,161.01,50.05z M103.08,12.17l-2.23,3.03c-0.03,0.05-0.06,0.1-0.06,0.16l-1.97,16.88
		c-0.01,0.08-0.05,0.16-0.11,0.22L83.07,46.14c-0.15,0.13-0.15,0.36-0.02,0.49l5.57,5.57c0.11,0.11,0.13,0.27,0.06,0.4l-6.81,12.65
		c-0.04,0.08-0.12,0.14-0.21,0.17l-22.67,5.91c-0.12,0.03-0.21,0.12-0.24,0.24l-7.19,25.57c-0.04,0.15-0.17,0.25-0.33,0.25H2.84
		c-0.31,0-0.46-0.38-0.23-0.59l97.44-87.69c0.14-0.12,0.34-0.11,0.47,0.02l2.53,2.63C103.17,11.86,103.18,12.04,103.08,12.17z"/>
	<path class="st0" d="M386.3,99.87c-0.55,0-1.09-0.22-1.49-0.66l-58.64-65.23V54.1c0,0.68-0.3,1.33-0.82,1.78l-6.61,5.62l5.32,5.32
		c0.62,0.62,0.84,1.52,0.58,2.36l-4.78,15.3c-0.23,0.76-0.83,1.34-1.6,1.55c-0.77,0.21-1.58,0.03-2.18-0.5l-33.29-29.38
		c-0.05-0.05-0.11-0.1-0.16-0.15c-0.07-0.05-0.13-0.1-0.2-0.16L232.18,9.17l-0.37,13.1c-0.02,0.72-0.36,1.38-0.94,1.81l-3.06,2.3
		v13.87c0,0.58-0.21,1.14-0.61,1.57l-8.92,9.81l17.11,28.52c0.5,0.84,0.43,1.91-0.19,2.67l-12.62,15.53
		c-0.41,0.51-1.04,0.83-1.7,0.86c-0.66,0.04-1.32-0.22-1.78-0.7l-43.74-44.73c-0.12-0.13-0.23-0.27-0.31-0.41l-24.17-27.39
		l-1.01,12.51l12.25,9.73c0.59,0.46,0.92,1.2,0.88,1.96v0l-1.17,21.52c-0.05,0.94-0.65,1.75-1.54,2.07
		c-0.89,0.32-1.87,0.08-2.51-0.62l-34.48-37.4l-19.85-20.69l-0.73,0.99l-1.92,16.44c-0.07,0.58-0.34,1.1-0.78,1.49L85.72,46.47
		l4.31,4.31c0.73,0.73,0.9,1.84,0.41,2.75l-6.81,12.66c-0.31,0.57-0.85,0.99-1.47,1.16l-21.74,5.67l-6.93,24.65
		c-0.28,1-1.21,1.71-2.25,1.71H2.84c-0.98,0-1.83-0.59-2.18-1.5s-0.11-1.92,0.62-2.58L98.72,7.61c0.94-0.85,2.37-0.8,3.25,0.12
		l2.44,2.54c0.01,0.01,0.02,0.01,0.03,0.02c0.23,0.17,0.42,0.37,0.56,0.6l20.03,20.88l20.89-14.36c0.98-0.67,2.3-0.5,3.08,0.38
		l1.79,2.02l26.16,29.65l49.23-48.27c0.89-0.88,2.31-0.9,3.23-0.04l54.55,50.65l36.43-26.84c0.94-0.69,2.26-0.58,3.06,0.25
		l2.17,2.23c0.1,0.1,0.18,0.2,0.25,0.32c0.12,0.08,0.23,0.18,0.33,0.28l61.6,68.52c0.74,0.82,0.67,2.09-0.15,2.82
		C387.25,99.7,386.78,99.87,386.3,99.87z M219.49,95.81C219.49,95.82,219.48,95.82,219.49,95.81L219.49,95.81z M7.17,95.37h42.8
		l6.85-24.35c0.23-0.8,0.87-1.42,1.66-1.63l21.97-5.73l5.88-10.92l-4.69-4.69c-0.46-0.46-0.71-1.09-0.68-1.73s0.31-1.26,0.8-1.68
		L96.9,31.38l1.9-16.25c0.05-0.4,0.2-0.78,0.43-1.11l1.4-1.91l-0.44-0.46L7.17,95.37z M179.62,52.4l41.04,41.97l10.7-13.17
		l-17.15-28.58c-0.53-0.89-0.43-2,0.27-2.77l9.32-10.25V25.54c0-0.74,0.35-1.44,0.95-1.88l3.08-2.31l0.45-15.8l-0.44-0.41
		L179.62,52.4z M316.05,83.28C316.04,83.28,316.04,83.28,316.05,83.28L316.05,83.28z M286.98,54.51l29.83,26.32l3.72-11.9
		l-5.88-5.88c-0.46-0.46-0.71-1.09-0.68-1.74c0.03-0.65,0.32-1.26,0.82-1.69l7.39-6.29V29.65c0-0.01,0-0.02,0-0.02l-0.59-0.61
		L286.98,54.51z M320.83,67.97c0,0,0,0.01,0,0.01L320.83,67.97z M127.79,34.7l30.26,32.83l0.91-16.74l-12.27-9.75
		c-0.61-0.48-0.94-1.24-0.88-2.01l1.4-17.24l-0.24-0.28L127.79,34.7z M81.15,63.47C81.15,63.48,81.15,63.48,81.15,63.47L81.15,63.47
		z M159.63,51.33c0,0,0.01,0.01,0.02,0.01L159.63,51.33z M161.01,50.05L161.01,50.05L161.01,50.05z M149.18,37.92L149.18,37.92
		C149.18,37.92,149.18,37.92,149.18,37.92z M227.15,26.86l-0.02,0.01C227.14,26.87,227.15,26.86,227.15,26.86z M228.47,20.87
		c0,0-0.01,0.01-0.02,0.01L228.47,20.87z M226.67,4.06C226.68,4.06,226.68,4.06,226.67,4.06L226.67,4.06z"/>
</g>
        </svg>

    )
}

export default LogoRange