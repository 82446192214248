const StarBlanket = ({fill1="#6DB1C7", fill2="#FF584F", fill3="#NONE", fill4="#574970",fill5="FF584F",  width, height}) => {
    

    return(<>
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 659.51 656.99" 
            width={width}
            height={height}
            xmlSpace="preserve"

            >
            <style type="text/css" fill="#00000">    
            </style>
            <g id="Layer_2" className="st0" fill={fill1} stroke="transparent">
                <path className="st1" d="M121.92,153.94L99.54,99.6l54.34,22.38l22.38,54.34L121.92,153.94z M561.07,559.95l-22.38-54.34l-54.34-22.38
                    l22.38,54.34L561.07,559.95z M528.52,591.62l-22.38-54.34L451.8,514.9l22.38,54.34L528.52,591.62z M418.41,655.36l22.6-54.25
                    l-22.6-54.25l-22.6,54.25L418.41,655.36z M495.35,623.49l-22.38-54.34l-54.34-22.38l22.38,54.34L495.35,623.49z M239.41,655.69
                    l22.6-54.25l-22.6-54.25l-22.6,54.25L239.41,655.69z M162.45,624.15l54.34-22.38l22.38-54.34l-54.34,22.38L162.45,624.15z
                    M419.95,1.57l-22.6,54.25l22.6,54.25l22.6-54.25L419.95,1.57z M496.45,32.83L442.1,55.2l-22.38,54.34l54.34-22.38L496.45,32.83z
                    M530.26,66.26l-54.34,22.38l-22.38,54.34l54.34-22.38L530.26,66.26z M658.21,238.53l-54.25-22.6l-54.25,22.6l54.25,22.6
                    L658.21,238.53z M626.34,161.58l-54.34,22.38l-22.38,54.34l54.34-22.38L626.34,161.58z M240.37,5.3l-22.6,54.25l22.6,54.25
                    l22.6-54.25L240.37,5.3z M163.42,37.18l22.38,54.34l54.34,22.38l-22.38-54.34L163.42,37.18z M129.15,66.26l22.38,54.34l54.34,22.38
                    L183.5,88.64L129.15,66.26z M141.96,204.15l-22.38-54.34l-54.34-22.38l22.38,54.34L141.96,204.15z M33.01,161.94l22.38,54.34
                    l54.34,22.38l-22.38-54.34L33.01,161.94z M1.3,238.5l54.25,22.6l54.25-22.6l-54.25-22.6L1.3,238.5z M1.3,282.91l54.25,22.6
                    l54.25-22.6l-54.25-22.6L1.3,282.91z M100.46,557.68l54.34-22.38l22.38-54.34l-54.34,22.38L100.46,557.68z M132.21,589.67
                    l54.34-22.38l22.38-54.34l-54.34,22.38L132.21,589.67z M655.24,374.15l-54.25-22.6l-54.25,22.6l54.25,22.6L655.24,374.15z
                    M655.07,328.94l-54.25-22.6l-54.25,22.6l54.25,22.6L655.07,328.94z M4.72,373.55l54.25,22.6l54.25-22.6l-54.25-22.6L4.72,373.55z
                    M5.18,328.34l54.25,22.6l54.25-22.6l-54.25-22.6L5.18,328.34z M561.04,97.73L506.7,120.1l-22.38,54.34l54.34-22.38L561.04,97.73z
                    M593.53,128.93l-54.34,22.38l-22.38,54.34l54.34-22.38L593.53,128.93z M329.48,654.84l22.6-54.25l-22.6-54.25l-22.6,54.25
                    L329.48,654.84z M374.68,654.89l22.6-54.25l-22.6-54.25l-22.6,54.25L374.68,654.89z M284.28,655.27l22.6-54.25l-22.6-54.25
                    l-22.6,54.25L284.28,655.27z M330.26,110.07l22.6-54.25l-22.6-54.25l-22.6,54.25L330.26,110.07z M286.04,3.27l-22.6,54.25
                    l22.6,54.25l22.6-54.25L286.04,3.27z M374.94,1.3l-22.6,54.25l22.6,54.25l22.6-54.25L374.94,1.3z M591.32,527.74l-22.38-54.34
                    l-54.34-22.38l22.38,54.34L591.32,527.74z M654.64,282.91l-54.25-22.6l-54.25,22.6l54.25,22.6L654.64,282.91z M66.77,525.89
                    l54.34-22.38l22.38-54.34l-54.34,22.38L66.77,525.89z M1.3,417.08l54.25,22.6l54.25-22.6l-54.25-22.6L1.3,417.08z M33.17,494.02
                    l54.34-22.38l22.38-54.34l-54.34,22.38L33.17,494.02z M625.29,496.06l-22.38-54.34l-54.34-22.38l22.38,54.34L625.29,496.06z
                    M657.4,419.33l-54.25-22.6l-54.25,22.6l54.25,22.6L657.4,419.33z"/>
            </g>
            <g id="Layer_7" fill={fill2} stroke="transparent">
                <path className="st2" d="M143.88,206.92l-0.06-0.02l-0.33-0.79l0.06,0.02L143.88,206.92z M515.91,450.53l-1.64-0.68l0.62,1.52
                    l1.64,0.68L515.91,450.53z M397.36,164.58l0.22,0.52l0.31-0.74L397.36,164.58z M284.39,546.51l-0.11,0.26l0.08,0.19l0.11-0.26
                    L284.39,546.51z M143.49,206.11l0.33,0.79l0.06,0.02l-0.33-0.79L143.49,206.11z M165.87,260.36l-0.11,0.04l0.16,0.07L165.87,260.36
                    z M165.83,260.24l0.04,0.12l0.12-0.05L165.83,260.24z M491.89,351.38l54.25,22.6l54.25-22.6l-54.25-22.6L491.89,351.38z
                    M462.28,227.97l54.34-22.38L539,151.25l-54.35,22.38L462.28,227.97z M514.27,449.85l-52.7-21.7l22.37,54.35l54.34,22.37
                    l-21.75-52.82l-1.64-0.68L514.27,449.85z M516.4,206.56l-22.38,54.34l54.34-22.37l22.38-54.35L516.4,206.56z M601.34,397.02
                    l-54.24-22.6l-54.25,22.6l28.68,11.95l-29.01-11.95l21.75,52.83l1.64,0.68l0.62,1.52l52.7,21.69l-22.31-54.2l0.18,0.08
                    L601.34,397.02z M548.36,283.73l54.25-22.6l-54.25-22.6l-54.25,22.6L548.36,283.73z M429.24,197.39l54.34-22.38l22.38-54.34
                    l-54.34,22.38L429.24,197.39z M548.74,283.74l-54.25,22.6l54.25,22.6l54.25-22.6L548.74,283.74z M429.24,459.84l22.38,54.34
                    l54.34,22.37l-22.38-54.34L429.24,459.84z M59.01,351.21l54.25,22.6l54.25-22.6l-54.25-22.6L59.01,351.21z M111.74,282.91
                    l54.02-22.51l0.11-0.04l-0.04-0.12l-21.95-53.32l-0.06-0.02l-0.33-0.79L89.2,183.75l22.27,54.06l-53.98,22.5L111.74,282.91z
                    M142.92,450.95l22.38-54.34l-54.34,22.37l-22.38,54.35L142.92,450.95z M110.96,373.78l-54.25,22.6l54.25,22.6l54.24-22.6
                    L110.96,373.78z M261.73,491.98l-54.35,22.38l-22.37,54.34l54.34-22.38L261.73,491.98z M285.99,113.32l-22.6-54.25l-22.61,54.25
                    l22.61,54.25L285.99,113.32z M176.6,176.35l54.34,22.38l-21.99-53.39l54.21,22.32l-22.38-54.34l-54.34-22.37l21.99,53.39
                    l-54.21-22.33L176.6,176.35z M198.16,426.97l-54.34,22.38l-22.38,54.34l54.34-22.38L198.16,426.97z M231.52,458.71l-54.34,22.37
                    l-22.38,54.35l54.35-22.38L231.52,458.71z M284.39,546.51l-22.52-54.06l-22.61,54.25l22.61,54.25l22.49-53.99l-0.08-0.19
                    L284.39,546.51z M114.24,283.42l-54.25,22.61l54.25,22.6l54.24-22.6L114.24,283.42z M284.39,546.51l0.08,0.19l-0.11,0.26
                    l22.52,54.06l22.61-54.25l-22.61-54.24L284.39,546.51z M374.86,546.02L352.4,492.1l-22.6,54.25l22.6,54.25l22.27-53.45l22.46,53.92
                    l22.6-54.25l-22.6-54.25L374.86,546.02z M143.88,206.92l54.28,22.35l-22.38-54.34l-54.34-22.37l22.05,53.55l0.06,0.02
                    L143.88,206.92z M397.89,164.36l53.81-22.16l22.38-54.34l-54.18,22.31l-22.32-53.56l-22.6,54.25l22.38,53.72L397.89,164.36z
                    M330.05,111.34l22.07,52.98l22.6-54.25l-22.6-54.25l-22.4,53.77l0.53,1.27L330.05,111.34z M330.05,111.34l-0.53-1.27l0.2-0.48
                    l-22.07-52.98l-22.6,54.25l22.6,54.24L330.05,111.34z M397.36,492.48l22.37,54.34l54.34,22.38l-22.37-54.34L397.36,492.48z
                    M284.39,546.51l-0.11,0.26l0.08,0.19l0.11-0.26L284.39,546.51z M329.72,109.59l-0.2,0.48l0.53,1.27l0.2-0.48L329.72,109.59z
                    M329.72,109.59l-0.2,0.48l0.53,1.27l0.2-0.48L329.72,109.59z M515.91,450.53l-1.64-0.68l0.62,1.52l1.64,0.68L515.91,450.53z"/>
            </g>
            <g id="Layer_6" fill={fill3} stroke="transparent">
                <path className="st3" d="M308.87,167.77l-0.14,0.34l-0.85-2.04l0.14-0.34L308.87,167.77z M198.25,229.22l-0.26-0.11l0.64,1.56
                    l0.26,0.11L198.25,229.22z M308.02,165.73l-0.14,0.34l0.85,2.04l0.14-0.34L308.02,165.73z M374.95,219.52l0.22,0.53l0.31-0.75
                    L374.95,219.52z M374.94,219.51v0.01h0.01L374.94,219.51z M198.89,230.78l-0.64-1.56l-0.26-0.11l0.64,1.56L198.89,230.78z
                    M220.58,283.45l-0.04-0.12l-54.09-22.54l-0.27,0.11l0.11,0.28l54.18,22.31l0.16,0.07L220.58,283.45z M166.14,305.87l0.31,0.13
                    l0.31-0.13l-0.31-0.13L166.14,305.87z M220.54,283.33l-54.09-22.54l-0.27,0.11l0.11,0.28l54.18,22.31l0.11-0.04l0.12-0.05
                    L220.54,283.33z M197.95,428.12l22.38-54.34l-54.34,22.37l-22.38,54.35L197.95,428.12z M230.73,461.01l-21.9,53.19l53.19-21.9
                    l21.9-53.19L230.73,461.01z M329.37,437.46l-22.61,54.25l22.61,54.25l22.6-54.25L329.37,437.46z M308.73,168.11l21.76,52.21
                    l22.39-53.75l22.06,52.94l0.01,0.01l0.53-0.22l53.81-22.15l22.37-54.34l-54.18,22.3l-22.31-53.56l-22.4,53.75l-22.28-53.48
                    l-22.47,53.91l0.85,2.04L308.73,168.11z M220.54,283.33l-21.65-52.55l-0.26-0.11l-0.64-1.56l-54.08-22.27l22.27,54.06l0.27-0.11
                    L220.54,283.33z M286.04,222.11l-22.37-54.34l-54.34-22.38l22.37,54.34L286.04,222.11z M252.19,406.13l-54.34,22.38l-22.38,54.34
                    l54.34-22.38L252.19,406.13z M165.99,350.95l-54.25,22.6l54.25,22.6l54.25-22.6L165.99,350.95z M198.89,230.78l54.08,22.26
                    L230.6,198.7l-54.35-22.38l21.74,52.79l0.26,0.11L198.89,230.78z M166.76,305.87l53.71-22.38l-54.18-22.31l-0.11-0.28l-53.98,22.5
                    l53.94,22.47l0.31-0.13L166.76,305.87z M351.97,491.76l22.6,54.24l22.61-54.24l-22.61-54.25L351.97,491.76z M374.8,437.41
                    l22.38,54.35l54.34,22.37l-22.38-54.34L374.8,437.41z M262.03,492.48l22.12,53.1l22.13-53.1l-22.13-53.1L262.03,492.48z
                    M166.45,350.95l54.25-22.61l-53.94-22.47l-0.31,0.13l-0.31-0.13l-53.94,22.47L166.45,350.95z M308.73,168.11l-0.85-2.04l0.14-0.34
                    l-21.75-52.21l-22.6,54.25l22.6,54.25L308.73,168.11z M406.89,251.99l54.34-22.37l22.37-54.34l-54.34,22.37L406.89,251.99z
                    M407.22,405.89l22.37,54.35l54.34,22.37l-22.37-54.34L407.22,405.89z M461.76,228.85l-22.38,54.35l54.34-22.38l22.38-54.34
                    L461.76,228.85z M493.72,306.03l54.25-22.61l-54.25-22.6l-54.24,22.6L493.72,306.03z M439.65,328.94l54.25,22.61l54.25-22.61
                    l-54.25-22.6L439.65,328.94z M494.07,396.75l54.25-22.6l-54.25-22.6l-54.25,22.6l29.63,12.34l-29.96-12.33l22.37,54.34l54.34,22.37
                    l-22.31-54.2L494.07,396.75z M166.45,305.74l-0.31,0.13l0.31,0.13l0.31-0.13L166.45,305.74z"/>
            </g>
            <g id="Layer_4" fill={fill4} stroke="transparent">
                <g>
                    <path className="st4" d="M235.03,521.13 M274.83,305.87l-0.11,0.05l0.16,0.07L274.83,305.87z M274.79,305.77l0.04,0.1l0.11-0.04
                        L274.79,305.77z M329.96,219.82l-0.21,0.5l0.53,1.28l0.21-0.5L329.96,219.82z M407.22,251.17l-0.33,0.81l0.04-0.02l0.33-0.81
                        L407.22,251.17z M407.22,251.17l-0.33,0.81l0.04-0.02l0.33-0.81L407.22,251.17z M284.17,437.89l0.06,0.13l9.26-22.49
                        L284.17,437.89z M284.17,437.89l0.06,0.13l9.26-22.49L284.17,437.89z M252.25,405.78l22.37-54.34l-54.34,22.37l-22.37,54.34
                        L252.25,405.78z M220.28,328.61l-54.24,22.6l54.24,22.6l54.25-22.6L220.28,328.61z M220.7,328.43l54.02-22.51l0.11-0.05l-0.04-0.1
                        l-22.29-54.12l-54.34-22.38l22.27,54.07l-53.98,22.49L220.7,328.43z M439.19,328.34l54.25-22.6l-54.25-22.6l-54.25,22.6
                        L439.19,328.34z M293.49,415.53l12.75-30.96l-54.34,22.38l-22.38,54.34l54.34-22.38l0.37-0.89l-0.06-0.13L293.49,415.53z
                        M307.66,275.45l-22.38-54.35l-54.34-22.37l22.37,54.34L307.66,275.45z M330.28,221.6l22.07,52.97l11.95-28.67l-11.71,28.43
                        l54.3-22.35l0.33-0.81l0.04-0.02l22.05-53.53l-54.34,22.37l-0.07,0.18l-22.55-54.1l-22.39,53.75l0.53,1.28L330.28,221.6z
                        M329.69,437.46l22.6,54.25l22.61-54.25l-22.61-54.25L329.69,437.46z M352.52,383.12l22.38,54.34l54.34,22.38l-22.38-54.34
                        L352.52,383.12z M406.89,251.98l-22.04,53.53l54.34-22.37l22.38-54.34l-54.31,22.35l-0.33,0.81L406.89,251.98z M439.22,373.98
                        l54.25-22.6l-54.25-22.6l-54.24,22.6l30,12.5l-30.34-12.49l22.38,54.34l54.34,22.37l-22.32-54.2L439.22,373.98z M330.28,221.6
                        l-0.53-1.28l0.21-0.5l-22.08-52.96l-22.6,54.24l22.6,54.25L330.28,221.6z M293.49,415.53l-9.26,22.49l22.55,54.11l22.6-54.24
                        l-22.6-54.25L293.49,415.53z M329.96,219.82l-0.21,0.5l0.53,1.28l0.21-0.5L329.96,219.82z"/>
                    <g>
                        <path className="st4" d="M235.03,522.63c1.93,0,1.93-3,0-3C233.1,519.63,233.1,522.63,235.03,522.63L235.03,522.63z"/>
                        <path className="st4" d="M274.08,304.57c-0.04,0.02-0.07,0.03-0.11,0.05c-0.23,0.13-0.4,0.31-0.54,0.54c-0.14,0.23-0.2,0.49-0.2,0.76
                            c0,0.27,0.07,0.52,0.2,0.76c0.13,0.23,0.31,0.4,0.54,0.54c0.05,0.02,0.09,0.04,0.14,0.06c0.01,0,0.01,0.01,0.02,0.01
                            c0.12,0.05,0.24,0.1,0.36,0.15c0.13,0.02,0.27,0.04,0.4,0.05c0.13-0.02,0.27-0.04,0.4-0.05c0.2-0.05,0.37-0.14,0.51-0.27
                            c0.11-0.07,0.2-0.16,0.27-0.27c0.09-0.09,0.16-0.2,0.19-0.33c0.07-0.12,0.1-0.24,0.1-0.38c0.04-0.2,0.04-0.4-0.03-0.6
                            c-0.02-0.04-0.03-0.08-0.05-0.12c-0.05-0.2-0.14-0.37-0.27-0.51c-0.11-0.16-0.25-0.29-0.42-0.39c-0.17-0.11-0.36-0.17-0.56-0.18
                            c-0.2-0.04-0.4-0.04-0.6,0.03C274.32,304.47,274.2,304.52,274.08,304.57c-0.23,0.13-0.4,0.31-0.54,0.54
                            c-0.05,0.12-0.1,0.24-0.15,0.36c-0.07,0.27-0.07,0.53,0,0.8c0.02,0.04,0.03,0.08,0.05,0.12c0.73-0.56,1.47-1.13,2.2-1.69
                            c-0.05-0.02-0.09-0.04-0.14-0.06c-0.01,0-0.01-0.01-0.02-0.01c0,0.86,0,1.73,0,2.59c0.04-0.02,0.07-0.03,0.11-0.05
                            c0.17-0.09,0.32-0.22,0.42-0.39c0.13-0.14,0.22-0.31,0.27-0.51c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56
                            c-0.08-0.1-0.16-0.2-0.23-0.3c-0.19-0.19-0.41-0.31-0.66-0.39c-0.13-0.02-0.27-0.04-0.4-0.05
                            C274.56,304.37,274.31,304.43,274.08,304.57z"/>
                        <path className="st4" d="M273.35,306.16c0.01,0.03,0.03,0.07,0.04,0.1c0.05,0.2,0.14,0.37,0.27,0.51c0.11,0.16,0.25,0.29,0.42,0.39
                            c0.12,0.05,0.24,0.1,0.36,0.15c0.27,0.07,0.53,0.07,0.8,0c0.04-0.01,0.07-0.03,0.11-0.04c0.12-0.05,0.24-0.1,0.36-0.15
                            c0.17-0.09,0.32-0.22,0.42-0.39c0.09-0.09,0.16-0.2,0.19-0.33c0.09-0.18,0.14-0.37,0.13-0.58c-0.02-0.13-0.04-0.27-0.05-0.4
                            c-0.05-0.12-0.1-0.24-0.15-0.36c-0.09-0.17-0.22-0.32-0.39-0.42c-0.14-0.13-0.31-0.22-0.51-0.27c-0.05-0.02-0.1-0.04-0.15-0.06
                            c-0.2-0.06-0.4-0.07-0.6-0.03c-0.2,0.01-0.39,0.07-0.56,0.18c-0.17,0.09-0.32,0.22-0.42,0.39c-0.13,0.14-0.22,0.31-0.27,0.51
                            c-0.02,0.13-0.04,0.27-0.05,0.4c0,0.27,0.07,0.52,0.2,0.76c0.08,0.1,0.16,0.2,0.23,0.3c0.19,0.19,0.41,0.31,0.66,0.39
                            c0.05,0.02,0.1,0.04,0.15,0.06c0-0.96,0-1.93,0-2.89c-0.04,0.01-0.07,0.03-0.11,0.04c0.62,0.35,1.23,0.7,1.85,1.05
                            c-0.01-0.03-0.03-0.07-0.04-0.1c-0.05-0.2-0.14-0.37-0.27-0.51c-0.11-0.16-0.25-0.29-0.42-0.39c-0.17-0.11-0.36-0.17-0.56-0.18
                            c-0.2-0.04-0.4-0.04-0.6,0.03c-0.12,0.05-0.24,0.1-0.36,0.15c-0.23,0.13-0.4,0.31-0.54,0.54c-0.05,0.12-0.1,0.24-0.15,0.36
                            C273.28,305.63,273.28,305.9,273.35,306.16z"/>
                        <path className="st4" d="M328.52,219.42c-0.05,0.13-0.11,0.26-0.16,0.39c-0.01,0.03-0.03,0.06-0.04,0.09c0,0.01-0.01,0.01-0.01,0.02
                            c0,0,0,0,0,0c-0.02,0.13-0.04,0.27-0.05,0.4c0.02,0.13,0.04,0.27,0.05,0.4c0.01,0.02,0.02,0.05,0.03,0.07
                            c0.13,0.3,0.25,0.61,0.38,0.91c0.04,0.1,0.08,0.2,0.12,0.3c0.05,0.12,0.1,0.24,0.15,0.36c0.09,0.17,0.22,0.32,0.39,0.42
                            c0.09,0.09,0.2,0.16,0.33,0.19c0.18,0.09,0.37,0.14,0.58,0.13c0.13-0.02,0.27-0.04,0.4-0.05c0.12-0.05,0.24-0.1,0.36-0.15
                            c0.17-0.09,0.32-0.22,0.42-0.39c0.13-0.14,0.22-0.31,0.27-0.51c0.05-0.13,0.11-0.26,0.16-0.39c0.01-0.03,0.03-0.06,0.04-0.09
                            c0-0.01,0.01-0.01,0.01-0.02c0,0,0,0,0,0c0.02-0.13,0.04-0.27,0.05-0.4c-0.02-0.13-0.04-0.27-0.05-0.4
                            c-0.01-0.02-0.02-0.05-0.03-0.07c-0.13-0.3-0.25-0.61-0.38-0.91c-0.04-0.1-0.08-0.2-0.12-0.3c-0.05-0.2-0.14-0.37-0.27-0.51
                            c-0.11-0.16-0.25-0.29-0.42-0.39c-0.17-0.11-0.36-0.17-0.56-0.18c-0.2-0.04-0.4-0.04-0.6,0.03c-0.36,0.12-0.71,0.34-0.9,0.69
                            C328.62,219.18,328.57,219.3,328.52,219.42c-0.07,0.27-0.07,0.53,0,0.8c0.18,0.43,0.35,0.85,0.53,1.28c0-0.27,0-0.53,0-0.8
                            c-0.07,0.17-0.14,0.33-0.21,0.5c0.96,0,1.93,0,2.89,0c-0.18-0.43-0.35-0.85-0.53-1.28c0,0.27,0,0.53,0,0.8
                            c0.07-0.17,0.14-0.33,0.21-0.5c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56c-0.09-0.17-0.22-0.32-0.39-0.42
                            c-0.14-0.13-0.31-0.22-0.51-0.27c-0.38-0.09-0.82-0.07-1.16,0.15c-0.1,0.08-0.2,0.16-0.3,0.23
                            C328.72,218.94,328.59,219.16,328.52,219.42z"/>
                        <path className="st4" d="M405.78,250.77c-0.1,0.24-0.19,0.47-0.29,0.71c-0.01,0.03-0.03,0.07-0.04,0.1c-0.02,0.13-0.04,0.27-0.05,0.4
                            c0.02,0.13,0.04,0.27,0.05,0.4c0.05,0.12,0.1,0.24,0.15,0.36c0.09,0.17,0.22,0.32,0.39,0.42c0.09,0.09,0.2,0.16,0.33,0.19
                            c0.12,0.07,0.24,0.1,0.38,0.1c0.13,0.04,0.27,0.04,0.4,0c0.2-0.01,0.39-0.07,0.56-0.18c0.01-0.01,0.03-0.01,0.04-0.02
                            c0,0,0.3-0.23,0.3-0.24c0.08-0.1,0.16-0.2,0.23-0.3c0.05-0.12,0.1-0.24,0.15-0.36c0,0,0,0,0,0c0.01-0.01,0.01-0.03,0.02-0.04
                            c0.02-0.06,0.05-0.12,0.07-0.18c0.06-0.16,0.13-0.31,0.19-0.47c0.02-0.04,0.03-0.08,0.05-0.12c0.02-0.13,0.04-0.27,0.05-0.4
                            c-0.02-0.13-0.04-0.27-0.05-0.4c-0.05-0.12-0.1-0.24-0.15-0.36c-0.09-0.17-0.22-0.32-0.39-0.42c-0.09-0.09-0.2-0.16-0.33-0.19
                            c-0.12-0.07-0.24-0.1-0.38-0.1c-0.13-0.04-0.27-0.04-0.4,0c-0.2,0.01-0.39,0.07-0.56,0.18c-0.01,0.01-0.03,0.01-0.04,0.02
                            c-0.17,0.09-0.32,0.22-0.42,0.39C405.91,250.4,405.82,250.57,405.78,250.77c-0.06,0.2-0.07,0.4-0.03,0.6
                            c0.01,0.2,0.07,0.39,0.18,0.56c0.08,0.1,0.16,0.2,0.23,0.3c0.19,0.19,0.41,0.31,0.66,0.39c0.13,0.02,0.27,0.04,0.4,0.05
                            c0.27,0,0.52-0.07,0.76-0.2c0.01-0.01,0.03-0.01,0.04-0.02c-0.73-0.56-1.47-1.13-2.2-1.69c-0.1,0.24-0.19,0.47-0.29,0.71
                            c-0.01,0.03-0.03,0.07-0.04,0.1c0.23-0.3,0.46-0.6,0.69-0.9c-0.01,0.01-0.03,0.01-0.04,0.02c0.73,0.56,1.47,1.13,2.2,1.69
                            c0.1-0.24,0.19-0.47,0.29-0.71c0.01-0.03,0.03-0.07,0.04-0.1c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56
                            c-0.09-0.17-0.22-0.32-0.39-0.42c-0.14-0.13-0.31-0.22-0.51-0.27c-0.13-0.02-0.27-0.04-0.4-0.05c-0.27,0-0.52,0.07-0.76,0.2
                            c-0.1,0.08-0.2,0.16-0.3,0.23C405.98,250.29,405.85,250.51,405.78,250.77z"/>
                        <path className="st4" d="M405.78,250.77c-0.1,0.24-0.19,0.47-0.29,0.71c-0.01,0.03-0.03,0.07-0.04,0.1c-0.02,0.13-0.04,0.27-0.05,0.4
                            c0.02,0.13,0.04,0.27,0.05,0.4c0.05,0.12,0.1,0.24,0.15,0.36c0.09,0.17,0.22,0.32,0.39,0.42c0.09,0.09,0.2,0.16,0.33,0.19
                            c0.12,0.07,0.24,0.1,0.38,0.1c0.13,0.04,0.27,0.04,0.4,0c0.2-0.01,0.39-0.07,0.56-0.18c0.01-0.01,0.03-0.01,0.04-0.02
                            c0,0,0.3-0.23,0.3-0.24c0.08-0.1,0.16-0.2,0.23-0.3c0.05-0.12,0.1-0.24,0.15-0.36c0,0,0,0,0,0c0.01-0.01,0.01-0.03,0.02-0.04
                            c0.02-0.06,0.05-0.12,0.07-0.18c0.06-0.16,0.13-0.31,0.19-0.47c0.02-0.04,0.03-0.08,0.05-0.12c0.02-0.13,0.04-0.27,0.05-0.4
                            c-0.02-0.13-0.04-0.27-0.05-0.4c-0.05-0.12-0.1-0.24-0.15-0.36c-0.09-0.17-0.22-0.32-0.39-0.42c-0.09-0.09-0.2-0.16-0.33-0.19
                            c-0.12-0.07-0.24-0.1-0.38-0.1c-0.13-0.04-0.27-0.04-0.4,0c-0.2,0.01-0.39,0.07-0.56,0.18c-0.01,0.01-0.03,0.01-0.04,0.02
                            c-0.17,0.09-0.32,0.22-0.42,0.39C405.91,250.4,405.82,250.57,405.78,250.77c-0.06,0.2-0.07,0.4-0.03,0.6
                            c0.01,0.2,0.07,0.39,0.18,0.56c0.08,0.1,0.16,0.2,0.23,0.3c0.19,0.19,0.41,0.31,0.66,0.39c0.13,0.02,0.27,0.04,0.4,0.05
                            c0.27,0,0.52-0.07,0.76-0.2c0.01-0.01,0.03-0.01,0.04-0.02c-0.73-0.56-1.47-1.13-2.2-1.69c-0.1,0.24-0.19,0.47-0.29,0.71
                            c-0.01,0.03-0.03,0.07-0.04,0.1c0.23-0.3,0.46-0.6,0.69-0.9c-0.01,0.01-0.03,0.01-0.04,0.02c0.73,0.56,1.47,1.13,2.2,1.69
                            c0.1-0.24,0.19-0.47,0.29-0.71c0.01-0.03,0.03-0.07,0.04-0.1c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56
                            c-0.09-0.17-0.22-0.32-0.39-0.42c-0.14-0.13-0.31-0.22-0.51-0.27c-0.13-0.02-0.27-0.04-0.4-0.05c-0.27,0-0.52,0.07-0.76,0.2
                            c-0.1,0.08-0.2,0.16-0.3,0.23C405.98,250.29,405.85,250.51,405.78,250.77z"/>
                        <path className="st4" d="M282.88,438.64c0.02,0.04,0.04,0.09,0.06,0.13c0.61,1.32,2.31,0.69,2.74-0.36c2.7-6.56,5.4-13.12,8.1-19.68
                            c0.39-0.94,0.77-1.87,1.16-2.81c0.31-0.76-0.34-1.64-1.05-1.85c-0.86-0.25-1.54,0.3-1.85,1.05c-2.71,6.51-5.43,13.03-8.14,19.54
                            c-0.39,0.94-0.78,1.88-1.18,2.82c-0.31,0.75,0.34,1.64,1.05,1.85c0.86,0.25,1.54-0.3,1.85-1.05c2.71-6.51,5.43-13.03,8.14-19.54
                            c0.39-0.94,0.78-1.88,1.18-2.82c-0.96-0.27-1.93-0.53-2.89-0.8c-2.7,6.56-5.4,13.12-8.1,19.68c-0.39,0.94-0.77,1.87-1.16,2.81
                            c0.91-0.12,1.83-0.24,2.74-0.36c-0.02-0.04-0.04-0.09-0.06-0.13C284.66,435.38,282.07,436.9,282.88,438.64z"/>
                        <path className="st4" d="M282.88,438.64c0.02,0.04,0.04,0.09,0.06,0.13c0.61,1.32,2.31,0.69,2.74-0.36c2.7-6.56,5.4-13.12,8.1-19.68
                            c0.39-0.94,0.77-1.87,1.16-2.81c0.31-0.76-0.34-1.64-1.05-1.85c-0.86-0.25-1.54,0.3-1.85,1.05c-2.71,6.51-5.43,13.03-8.14,19.54
                            c-0.39,0.94-0.78,1.88-1.18,2.82c-0.31,0.75,0.34,1.64,1.05,1.85c0.86,0.25,1.54-0.3,1.85-1.05c2.71-6.51,5.43-13.03,8.14-19.54
                            c0.39-0.94,0.78-1.88,1.18-2.82c-0.96-0.27-1.93-0.53-2.89-0.8c-2.7,6.56-5.4,13.12-8.1,19.68c-0.39,0.94-0.77,1.87-1.16,2.81
                            c0.91-0.12,1.83-0.24,2.74-0.36c-0.02-0.04-0.04-0.09-0.06-0.13C284.66,435.38,282.07,436.9,282.88,438.64z"/>
                        <path className="st4" d="M253.7,406.17c2.53-6.14,5.06-12.28,7.58-18.42c4.01-9.74,8.02-19.47,12.03-29.21
                            c0.92-2.24,1.84-4.47,2.76-6.71c0.41-1.01-0.84-2.26-1.85-1.85c-6.21,2.56-12.43,5.12-18.64,7.67
                            c-9.74,4.01-19.48,8.02-29.23,12.03c-2.13,0.88-4.33,1.66-6.42,2.64c-0.86,0.41-1.07,1-1.4,1.79c-0.46,1.11-0.91,2.22-1.37,3.33
                            c-3.82,9.28-7.64,18.56-11.46,27.84c-1.96,4.76-3.92,9.51-5.87,14.27c-1.07,2.6-2.25,5.18-3.22,7.82
                            c-0.05,0.12-0.1,0.24-0.15,0.37c-0.41,1.01,0.84,2.26,1.85,1.85c6.14-2.53,12.28-5.06,18.42-7.58
                            c9.74-4.01,19.47-8.02,29.21-12.03c2.24-0.92,4.47-1.84,6.71-2.76c1.76-0.72,0.99-3.63-0.8-2.89
                            c-6.14,2.53-12.28,5.06-18.42,7.58c-9.74,4.01-19.47,8.02-29.21,12.03c-2.24,0.92-4.47,1.84-6.71,2.76
                            c0.62,0.62,1.23,1.23,1.85,1.85c2.53-6.14,5.06-12.28,7.58-18.42c4.01-9.74,8.02-19.47,12.03-29.21
                            c0.92-2.24,1.84-4.47,2.76-6.71c-0.35,0.35-0.7,0.7-1.05,1.05c6.14-2.53,12.28-5.06,18.42-7.58c9.74-4.01,19.47-8.02,29.21-12.03
                            c2.24-0.92,4.47-1.84,6.71-2.76c-0.62-0.62-1.23-1.23-1.85-1.85c-2.53,6.14-5.06,12.28-7.58,18.42
                            c-4.01,9.74-8.02,19.47-12.03,29.21c-0.92,2.24-1.84,4.47-2.76,6.71C250.07,407.16,252.97,407.94,253.7,406.17z"/>
                        <path className="st4" d="M219.89,327.16c-6.13,2.55-12.26,5.11-18.39,7.66c-9.72,4.05-19.44,8.1-29.16,12.15
                            c-2.23,0.93-4.46,1.86-6.7,2.79c-1.41,0.59-1.42,2.3,0,2.89c6.13,2.55,12.26,5.11,18.39,7.66c9.77,4.07,19.54,8.14,29.31,12.21
                            c1.47,0.61,2.94,1.23,4.42,1.84c0.66,0.28,1.35,0.67,2.05,0.85c0.67,0.18,1.07-0.05,1.65-0.29c1.11-0.46,2.22-0.92,3.33-1.39
                            c9.32-3.88,18.63-7.76,27.95-11.64c7.28-3.03,14.55-6.06,21.83-9.09c0.12-0.05,0.24-0.1,0.37-0.15c1.41-0.59,1.42-2.3,0-2.89
                            c-6.13-2.55-12.26-5.11-18.39-7.66c-9.72-4.05-19.44-8.1-29.16-12.15c-2.23-0.93-4.46-1.86-6.7-2.79
                            c-1.78-0.74-2.56,2.16-0.8,2.89c6.13,2.55,12.26,5.11,18.39,7.66c9.72,4.05,19.44,8.1,29.16,12.15c2.23,0.93,4.46,1.86,6.7,2.79
                            c0-0.96,0-1.93,0-2.89c-6.13,2.55-12.26,5.11-18.39,7.66c-9.69,4.04-19.39,8.08-29.08,12.12c-1.52,0.63-3.04,1.27-4.56,1.9
                            c-0.41,0.17-1.87,0.5-2.11,0.88c-0.13,0.21,0.73,0.22,0.59,0c-0.09-0.14-0.62-0.26-0.77-0.32c-1.14-0.48-2.29-0.95-3.43-1.43
                            c-9.29-3.87-18.58-7.74-27.87-11.61c-4.66-1.94-9.32-3.89-13.99-5.83c-2.55-1.06-5.1-2.32-7.72-3.22
                            c-0.12-0.04-0.24-0.1-0.37-0.15c0,0.96,0,1.93,0,2.89c6.13-2.55,12.26-5.11,18.39-7.66c9.72-4.05,19.44-8.1,29.16-12.15
                            c2.23-0.93,4.46-1.86,6.7-2.79c0.75-0.31,1.27-0.99,1.05-1.85C221.54,327.5,220.63,326.85,219.89,327.16z"/>
                        <path className="st4" d="M221.1,329.87c6.17-2.57,12.34-5.14,18.51-7.71c9.73-4.06,19.47-8.11,29.2-12.17
                            c2.26-0.94,4.55-1.82,6.78-2.83c0.54-0.25,0.91-1.13,0.69-1.69c-2.45-6.13-5.03-12.21-7.54-18.31
                            c-4.04-9.81-8.08-19.62-12.12-29.43c-0.88-2.13-1.68-4.32-2.64-6.42c-0.4-0.87-1.01-1.08-1.79-1.41
                            c-1.09-0.45-2.18-0.9-3.26-1.34c-9.3-3.83-18.6-7.66-27.9-11.49c-4.76-1.96-9.51-3.92-14.27-5.88c-2.6-1.07-5.18-2.26-7.82-3.22
                            c-0.12-0.05-0.24-0.1-0.37-0.15c-1.01-0.41-2.26,0.84-1.85,1.85c2.51,6.09,5.02,12.19,7.53,18.28c4,9.71,8,19.42,12,29.12
                            c0.91,2.22,1.83,4.44,2.74,6.66c0.35-0.62,0.7-1.23,1.05-1.85c-6.08,2.53-12.17,5.07-18.25,7.6
                            c-9.69,4.04-19.38,8.08-29.08,12.11c-2.22,0.92-4.44,1.85-6.65,2.77c-1.41,0.59-1.42,2.3,0,2.89c6.13,2.55,12.26,5.11,18.39,7.66
                            c9.72,4.05,19.44,8.1,29.16,12.15c2.23,0.93,4.46,1.86,6.7,2.79c1.78,0.74,2.56-2.16,0.8-2.89c-6.13-2.55-12.26-5.11-18.39-7.66
                            c-9.72-4.05-19.44-8.1-29.16-12.15c-2.23-0.93-4.46-1.86-6.7-2.79c0,0.96,0,1.93,0,2.89c6.08-2.53,12.17-5.07,18.25-7.6
                            c9.69-4.04,19.38-8.08,29.08-12.11c2.22-0.92,4.44-1.85,6.65-2.77c0.68-0.28,1.39-1.02,1.05-1.85
                            c-2.51-6.09-5.02-12.19-7.53-18.28c-4-9.71-8-19.42-12-29.12c-0.91-2.22-1.83-4.44-2.74-6.66c-0.62,0.62-1.23,1.23-1.85,1.85
                            c6.14,2.53,12.28,5.06,18.42,7.59c9.74,4.01,19.47,8.02,29.21,12.03c2.24,0.92,4.47,1.84,6.71,2.76c-0.35-0.35-0.7-0.7-1.05-1.05
                            c2.53,6.14,5.06,12.29,7.59,18.43c4.01,9.74,8.02,19.47,12.03,29.21c0.9,2.19,1.83,4.38,2.71,6.58c0.23-0.56,0.46-1.13,0.69-1.69
                            c0.33-0.15,0.24-0.1-0.1,0.04c-0.34,0.14-0.69,0.29-1.03,0.43c-1.3,0.54-2.6,1.08-3.89,1.62c-4.3,1.79-8.61,3.59-12.91,5.38
                            c-9.71,4.05-19.43,8.09-29.14,12.14c-2.23,0.93-4.47,1.86-6.7,2.79c-0.75,0.31-1.27,0.99-1.05,1.85
                            C219.44,329.53,220.36,330.18,221.1,329.87z"/>
                        <path className="st4" d="M439.59,329.78c6.13-2.55,12.26-5.11,18.39-7.66c9.72-4.05,19.44-8.1,29.16-12.15
                            c2.23-0.93,4.46-1.86,6.7-2.79c1.41-0.59,1.42-2.3,0-2.89c-6.13-2.55-12.26-5.11-18.39-7.66c-9.77-4.07-19.55-8.14-29.32-12.21
                            c-1.47-0.61-2.95-1.23-4.42-1.84c-0.66-0.28-1.35-0.67-2.05-0.85c-0.67-0.18-1.07,0.05-1.65,0.29c-1.11,0.46-2.22,0.92-3.33,1.39
                            c-9.32,3.88-18.63,7.76-27.95,11.64c-7.28,3.03-14.55,6.06-21.83,9.09c-0.12,0.05-0.24,0.1-0.37,0.15c-1.41,0.59-1.42,2.3,0,2.89
                            c6.13,2.55,12.26,5.11,18.39,7.66c9.72,4.05,19.44,8.1,29.16,12.15c2.23,0.93,4.46,1.86,6.7,2.79c1.78,0.74,2.56-2.16,0.8-2.89
                            c-6.13-2.55-12.26-5.11-18.39-7.66c-9.72-4.05-19.44-8.1-29.16-12.15c-2.23-0.93-4.46-1.86-6.7-2.79c0,0.96,0,1.93,0,2.89
                            c6.13-2.55,12.26-5.11,18.39-7.66c9.69-4.04,19.39-8.08,29.08-12.12c1.52-0.63,3.04-1.27,4.56-1.9c0.41-0.17,1.87-0.5,2.11-0.88
                            c0.13-0.21-0.73-0.22-0.59,0c0.09,0.14,0.62,0.26,0.77,0.32c1.14,0.48,2.29,0.95,3.43,1.43c9.29,3.87,18.58,7.74,27.87,11.61
                            c4.66,1.94,9.33,3.89,13.99,5.83c2.56,1.06,5.1,2.32,7.73,3.22c0.12,0.04,0.24,0.1,0.37,0.15c0-0.96,0-1.93,0-2.89
                            c-6.13,2.55-12.26,5.11-18.39,7.66c-9.72,4.05-19.44,8.1-29.16,12.15c-2.23,0.93-4.46,1.86-6.7,2.79
                            c-0.75,0.31-1.27,0.99-1.05,1.85C437.93,329.44,438.85,330.09,439.59,329.78z"/>
                        <path className="st4" d="M294.94,415.92c3.73-9.05,7.46-18.11,11.18-27.16c0.52-1.27,1.04-2.53,1.57-3.8
                            c0.41-1.01-0.84-2.26-1.85-1.85c-6.21,2.56-12.43,5.12-18.64,7.68c-9.74,4.01-19.48,8.02-29.23,12.04
                            c-2.13,0.88-4.33,1.66-6.42,2.64c-0.86,0.41-1.07,1-1.4,1.79c-0.46,1.11-0.91,2.22-1.37,3.33c-3.82,9.28-7.64,18.56-11.46,27.84
                            c-1.96,4.76-3.92,9.51-5.88,14.27c-1.07,2.6-2.26,5.18-3.22,7.82c-0.05,0.12-0.1,0.24-0.15,0.37c-0.41,1.01,0.84,2.26,1.85,1.85
                            c6.58-2.71,13.17-5.42,19.75-8.13c9.87-4.06,19.73-8.13,29.6-12.19c1.38-0.57,2.76-1.14,4.15-1.71c0.27-0.11,0.54-0.21,0.8-0.33
                            c1.16-0.53,1.79-2.06,1.25-3.24c0.05,0.39,0.1,0.77,0.15,1.16c2.71-6.51,5.43-13.02,8.14-19.53
                            C294.15,417.81,294.55,416.87,294.94,415.92c0.74-1.78-2.16-2.56-2.89-0.8c-2.8,6.71-5.6,13.42-8.39,20.14
                            c-0.48,1.14-1.28,2.29-0.72,3.51c-0.05-0.39-0.1-0.77-0.15-1.16c-0.12,0.3-0.25,0.59-0.37,0.89c0.35-0.35,0.7-0.7,1.05-1.05
                            c-6.14,2.53-12.28,5.06-18.42,7.59c-9.74,4.01-19.47,8.02-29.21,12.03c-2.24,0.92-4.47,1.84-6.71,2.76
                            c0.62,0.62,1.23,1.23,1.85,1.85c2.53-6.14,5.06-12.28,7.59-18.42c4.01-9.74,8.02-19.47,12.03-29.21
                            c0.92-2.24,1.84-4.47,2.76-6.71c-0.35,0.35-0.7,0.7-1.05,1.05c6.14-2.53,12.28-5.06,18.42-7.59c9.74-4.01,19.47-8.02,29.21-12.03
                            c2.24-0.92,4.47-1.84,6.71-2.76c-0.62-0.62-1.23-1.23-1.85-1.85c-3.73,9.05-7.46,18.11-11.18,27.16
                            c-0.52,1.27-1.04,2.53-1.57,3.8C291.31,416.91,294.21,417.69,294.94,415.92z"/>
                        <path className="st4" d="M309.11,275.05c-2.56-6.21-5.12-12.43-7.68-18.64c-4.01-9.74-8.02-19.49-12.04-29.23
                            c-0.88-2.13-1.66-4.33-2.64-6.42c-0.41-0.86-1-1.07-1.79-1.4c-1.11-0.46-2.22-0.91-3.33-1.37c-9.28-3.82-18.56-7.64-27.84-11.46
                            c-4.76-1.96-9.51-3.92-14.27-5.87c-2.6-1.07-5.18-2.25-7.82-3.22c-0.12-0.05-0.24-0.1-0.37-0.15c-1.01-0.41-2.26,0.84-1.85,1.85
                            c2.56,6.21,5.12,12.43,7.67,18.64c4.01,9.74,8.02,19.48,12.03,29.23c0.88,2.13,1.66,4.33,2.64,6.42c0.41,0.86,1,1.07,1.79,1.4
                            c1.11,0.46,2.22,0.91,3.33,1.37c9.28,3.82,18.56,7.64,27.84,11.46c4.76,1.96,9.51,3.92,14.27,5.88c2.6,1.07,5.18,2.26,7.83,3.22
                            c0.12,0.05,0.24,0.1,0.37,0.15c1.78,0.73,2.56-2.17,0.8-2.89c-6.14-2.53-12.28-5.06-18.42-7.59
                            c-9.74-4.01-19.48-8.02-29.22-12.03c-2.24-0.92-4.47-1.84-6.71-2.76c0.35,0.35,0.7,0.7,1.05,1.05
                            c-2.53-6.14-5.06-12.28-7.58-18.42c-4.01-9.74-8.02-19.47-12.03-29.21c-0.92-2.24-1.84-4.47-2.76-6.71
                            c-0.62,0.62-1.23,1.23-1.85,1.85c6.14,2.53,12.28,5.06,18.42,7.58c9.74,4.01,19.47,8.02,29.21,12.03
                            c2.24,0.92,4.47,1.84,6.71,2.76c-0.35-0.35-0.7-0.7-1.05-1.05c2.53,6.14,5.06,12.28,7.59,18.42c4.01,9.74,8.02,19.48,12.03,29.22
                            c0.92,2.24,1.84,4.47,2.76,6.71C306.94,277.61,309.85,276.83,309.11,275.05z"/>
                        <path className="st4" d="M328.84,221.99c2.5,5.99,5,11.99,7.49,17.98c3.95,9.47,7.89,18.94,11.84,28.41
                            c0.91,2.19,1.83,4.39,2.74,6.58c0.59,1.41,2.3,1.42,2.89,0c3.49-8.38,6.99-16.76,10.48-25.14c0.49-1.18,0.98-2.35,1.47-3.53
                            c-0.96-0.27-1.93-0.53-2.89-0.8c-3.41,8.29-6.83,16.57-10.24,24.86c-0.49,1.19-0.98,2.38-1.47,3.57
                            c-0.41,1.01,0.84,2.26,1.85,1.85c6.43-2.65,12.86-5.29,19.29-7.94c9.82-4.04,19.63-8.08,29.45-12.12
                            c1.48-0.61,2.97-1.22,4.45-1.83c0.33-0.13,0.65-0.27,0.98-0.4c0.92-0.38,1.15-1.05,1.51-1.91c-0.23,0.3-0.46,0.6-0.69,0.9
                            c0.64-0.32,0.94-1.43,1.21-2.08c0.51-1.25,1.03-2.5,1.54-3.74c1.74-4.23,3.48-8.46,5.22-12.68c4-9.72,8.01-19.44,12.01-29.16
                            c0.93-2.26,1.86-4.52,2.79-6.78c0.41-1.01-0.84-2.26-1.85-1.85c-6.29,2.59-12.57,5.18-18.86,7.76
                            c-9.82,4.04-19.64,8.09-29.47,12.13c-1.53,0.63-3.06,1.26-4.6,1.89c-1.03,0.43-2.09,0.68-2.53,1.81c0.96,0,1.93,0,2.89,0
                            c-2.54-6.11-5.09-12.21-7.63-18.32c-4.04-9.69-8.08-19.38-12.12-29.07c-0.93-2.24-1.87-4.48-2.8-6.71c-0.59-1.41-2.3-1.42-2.89,0
                            c-2.69,6.45-5.38,12.91-8.07,19.36c-4.08,9.79-8.16,19.58-12.24,29.37c-0.58,1.39-1.16,2.78-1.74,4.17
                            c-0.11,0.25-0.26,0.52-0.33,0.79c-0.09,0.33-0.08,0.63,0.02,0.96c0.08,0.27,0.23,0.7,0.39,0.93c0.42,0.62-0.49-0.84,0.07-0.47
                            c0.01,0.01-0.17,0.4-0.18,0.42c-0.32,0.75,0.34,1.64,1.05,1.85c0.86,0.25,1.53-0.3,1.85-1.05c0.25-0.59,0.29-1.02,0.07-1.63
                            c-0.05-0.14-0.19-0.71-0.33-0.79c-0.32-0.19,0.12,0.5-0.06,0.62c0.1-0.06,0.2-0.48,0.25-0.59c0.44-1.07,0.89-2.13,1.33-3.2
                            c1.74-4.17,3.47-8.33,5.21-12.5c4.21-10.1,8.41-20.2,12.62-30.3c0.99-2.38,1.98-4.76,2.97-7.14c-0.96,0-1.93,0-2.89,0
                            c2.54,6.11,5.09,12.21,7.63,18.32c4.04,9.69,8.08,19.38,12.12,29.07c0.93,2.24,1.87,4.48,2.8,6.71c0.58,1.39,2.33,1.45,2.89,0
                            c0.02-0.06,0.05-0.12,0.07-0.18c-0.35,0.35-0.7,0.7-1.05,1.05c6.14-2.53,12.28-5.06,18.42-7.58c9.74-4.01,19.47-8.02,29.21-12.03
                            c2.24-0.92,4.47-1.84,6.71-2.76c-0.62-0.62-1.23-1.23-1.85-1.85c-2.49,6.05-4.98,12.09-7.47,18.14
                            c-3.95,9.59-7.9,19.18-11.85,28.77c-0.91,2.21-1.82,4.42-2.73,6.62c0.23-0.3,0.46-0.6,0.69-0.9c-0.62,0.31-0.81,1.13-1.06,1.73
                            c0.35-0.35,0.7-0.7,1.05-1.05c-6.14,2.53-12.27,5.05-18.41,7.58c-9.73,4-19.46,8.01-29.19,12.01c-2.23,0.92-4.47,1.84-6.7,2.76
                            c0.62,0.62,1.23,1.23,1.85,1.85c3.41-8.29,6.83-16.57,10.24-24.86c0.49-1.19,0.98-2.38,1.47-3.57c0.74-1.79-2.16-2.55-2.89-0.8
                            c-3.49,8.38-6.99,16.76-10.48,25.14c-0.49,1.18-0.98,2.35-1.47,3.53c0.96,0,1.93,0,2.89,0c-2.5-5.99-5-11.99-7.49-17.98
                            c-3.95-9.47-7.89-18.94-11.84-28.41c-0.91-2.19-1.83-4.39-2.74-6.58c-0.31-0.75-0.99-1.27-1.85-1.05
                            C329.18,220.34,328.53,221.25,328.84,221.99z"/>
                        <path className="st4" d="M328.25,437.85c2.55,6.13,5.11,12.26,7.66,18.39c4.05,9.72,8.1,19.44,12.15,29.16
                            c0.93,2.23,1.86,4.46,2.79,6.7c0.59,1.41,2.3,1.42,2.89,0c2.55-6.13,5.11-12.26,7.66-18.39c4.07-9.77,8.15-19.55,12.22-29.32
                            c0.61-1.47,1.23-2.95,1.84-4.42c0.28-0.66,0.67-1.35,0.85-2.05c0.18-0.67-0.05-1.07-0.29-1.65c-0.46-1.11-0.92-2.22-1.39-3.33
                            c-3.88-9.32-7.77-18.63-11.65-27.95c-3.03-7.28-6.06-14.55-9.1-21.83c-0.05-0.12-0.1-0.24-0.15-0.37c-0.59-1.41-2.3-1.42-2.89,0
                            c-2.55,6.13-5.11,12.26-7.66,18.39c-4.05,9.72-8.1,19.44-12.15,29.16c-0.93,2.23-1.86,4.46-2.79,6.7
                            c-0.74,1.78,2.16,2.56,2.89,0.8c2.55-6.13,5.11-12.26,7.66-18.39c4.05-9.72,8.1-19.44,12.15-29.16c0.93-2.23,1.86-4.46,2.79-6.7
                            c-0.96,0-1.93,0-2.89,0c2.55,6.13,5.11,12.26,7.66,18.39c4.04,9.69,8.08,19.39,12.12,29.08c0.63,1.52,1.27,3.04,1.9,4.56
                            c0.17,0.41,0.5,1.87,0.88,2.11c0.21,0.13,0.22-0.73,0-0.59c-0.14,0.09-0.26,0.62-0.32,0.77c-0.48,1.14-0.95,2.29-1.43,3.43
                            c-3.87,9.29-7.74,18.58-11.62,27.87c-1.94,4.66-3.89,9.33-5.83,13.99c-1.06,2.56-2.32,5.1-3.22,7.73
                            c-0.04,0.12-0.1,0.24-0.15,0.37c0.96,0,1.93,0,2.89,0c-2.55-6.13-5.11-12.26-7.66-18.39c-4.05-9.72-8.1-19.44-12.15-29.16
                            c-0.93-2.23-1.86-4.46-2.79-6.7c-0.31-0.75-0.99-1.27-1.85-1.05C328.59,436.2,327.94,437.11,328.25,437.85z"/>
                        <path className="st4" d="M351.08,383.51c2.56,6.21,5.12,12.43,7.68,18.64c4.01,9.74,8.02,19.48,12.04,29.23
                            c0.88,2.13,1.66,4.33,2.64,6.42c0.41,0.86,1,1.07,1.79,1.4c1.11,0.46,2.22,0.91,3.33,1.37c9.28,3.82,18.56,7.64,27.84,11.46
                            c4.76,1.96,9.51,3.92,14.27,5.88c2.6,1.07,5.18,2.26,7.82,3.22c0.12,0.05,0.24,0.1,0.37,0.15c1.01,0.41,2.26-0.84,1.85-1.85
                            c-2.56-6.21-5.12-12.43-7.68-18.64c-4.01-9.74-8.02-19.48-12.04-29.23c-0.88-2.13-1.66-4.33-2.64-6.42
                            c-0.41-0.86-1-1.07-1.79-1.4c-1.11-0.46-2.22-0.91-3.33-1.37c-9.28-3.82-18.56-7.64-27.84-11.46c-4.76-1.96-9.51-3.92-14.27-5.88
                            c-2.6-1.07-5.18-2.26-7.82-3.22c-0.12-0.05-0.24-0.1-0.37-0.15c-1.78-0.73-2.56,2.17-0.8,2.89c6.14,2.53,12.28,5.06,18.42,7.59
                            c9.74,4.01,19.47,8.02,29.21,12.03c2.24,0.92,4.47,1.84,6.71,2.76c-0.35-0.35-0.7-0.7-1.05-1.05c2.53,6.14,5.06,12.28,7.59,18.42
                            c4.01,9.74,8.02,19.47,12.03,29.21c0.92,2.24,1.84,4.47,2.76,6.71c0.62-0.62,1.23-1.23,1.85-1.85
                            c-6.14-2.53-12.28-5.06-18.42-7.59c-9.74-4.01-19.47-8.02-29.21-12.03c-2.24-0.92-4.47-1.84-6.71-2.76
                            c0.35,0.35,0.7,0.7,1.05,1.05c-2.53-6.14-5.06-12.28-7.59-18.42c-4.01-9.74-8.02-19.47-12.03-29.21
                            c-0.92-2.24-1.84-4.47-2.76-6.71C353.25,380.96,350.34,381.73,351.08,383.51z"/>
                        <path className="st4" d="M405.45,251.58c-2.49,6.05-4.98,12.09-7.47,18.14c-3.95,9.59-7.9,19.18-11.85,28.77
                            c-0.91,2.21-1.82,4.42-2.73,6.62c-0.41,1.01,0.84,2.26,1.85,1.85c6.21-2.56,12.43-5.12,18.64-7.67
                            c9.74-4.01,19.48-8.02,29.23-12.03c2.13-0.88,4.33-1.66,6.42-2.64c0.86-0.41,1.07-1,1.4-1.79c0.46-1.11,0.91-2.22,1.37-3.33
                            c3.82-9.28,7.64-18.56,11.46-27.84c1.96-4.76,3.92-9.51,5.88-14.27c1.07-2.6,2.26-5.18,3.22-7.82c0.05-0.12,0.1-0.24,0.15-0.37
                            c0.41-1.01-0.84-2.26-1.85-1.85c-6.43,2.65-12.86,5.29-19.29,7.94c-9.82,4.04-19.64,8.08-29.46,12.12
                            c-1.48,0.61-2.97,1.22-4.45,1.83c-0.33,0.13-0.65,0.27-0.98,0.4c-0.92,0.38-1.15,1.05-1.51,1.91c0.23-0.3,0.46-0.6,0.69-0.9
                            c-0.01,0.01-0.03,0.01-0.04,0.02c-1.73,0.86-0.21,3.45,1.51,2.59c0.63-0.31,0.81-1.13,1.06-1.73c-0.35,0.35-0.7,0.7-1.05,1.05
                            c6.14-2.53,12.27-5.05,18.41-7.58c9.73-4,19.46-8.01,29.19-12.01c2.23-0.92,4.47-1.84,6.7-2.76c-0.62-0.62-1.23-1.23-1.85-1.85
                            c-2.53,6.14-5.06,12.28-7.59,18.42c-4.01,9.74-8.02,19.47-12.03,29.21c-0.92,2.24-1.84,4.47-2.76,6.71
                            c0.35-0.35,0.7-0.7,1.05-1.05c-6.14,2.53-12.28,5.06-18.42,7.58c-9.74,4.01-19.47,8.02-29.21,12.03
                            c-2.24,0.92-4.47,1.84-6.71,2.76c0.62,0.62,1.23,1.23,1.85,1.85c2.49-6.05,4.98-12.09,7.47-18.14
                            c3.95-9.59,7.9-19.18,11.85-28.77c0.91-2.21,1.82-4.42,2.73-6.62C409.08,250.59,406.17,249.81,405.45,251.58z"/>
                        <path className="st4" d="M439.62,375.42c6.13-2.55,12.26-5.11,18.39-7.66c9.72-4.05,19.44-8.1,29.16-12.15
                            c2.23-0.93,4.46-1.86,6.7-2.79c1.41-0.59,1.42-2.3,0-2.89c-6.13-2.55-12.26-5.11-18.39-7.66c-9.77-4.07-19.55-8.14-29.32-12.21
                            c-1.47-0.61-2.95-1.23-4.42-1.84c-0.66-0.28-1.35-0.67-2.05-0.85c-0.67-0.18-1.07,0.05-1.65,0.29c-1.11,0.46-2.22,0.92-3.33,1.39
                            c-9.32,3.88-18.63,7.76-27.95,11.64c-7.27,3.03-14.55,6.06-21.82,9.09c-0.12,0.05-0.24,0.1-0.37,0.15c-1.41,0.59-1.42,2.3,0,2.89
                            c8.76,3.65,17.51,7.3,26.27,10.94c1.24,0.52,2.49,1.04,3.73,1.56c0.27-0.96,0.53-1.93,0.8-2.89c-8.85-3.64-17.7-7.29-26.56-10.93
                            c-1.26-0.52-2.52-1.04-3.78-1.56c-1.01-0.41-2.26,0.84-1.85,1.85c2.56,6.21,5.12,12.43,7.68,18.64
                            c4.01,9.74,8.02,19.48,12.04,29.23c0.88,2.13,1.66,4.33,2.64,6.42c0.41,0.86,1,1.07,1.79,1.4c1.11,0.46,2.22,0.91,3.33,1.37
                            c9.28,3.82,18.56,7.64,27.84,11.46c4.76,1.96,9.51,3.92,14.27,5.87c2.6,1.07,5.18,2.25,7.82,3.22c0.12,0.05,0.24,0.1,0.37,0.15
                            c1.01,0.41,2.26-0.84,1.85-1.85c-2.52-6.12-5.04-12.23-7.56-18.35c-4-9.71-8-19.42-11.99-29.12c-0.92-2.24-1.85-4.48-2.77-6.73
                            c-0.73,0.56-1.47,1.13-2.2,1.69c0.06,0.03,0.12,0.05,0.18,0.08c1.75,0.78,3.28-1.81,1.51-2.59c-0.06-0.03-0.12-0.05-0.18-0.08
                            c-1.02-0.45-2.76,0.34-2.2,1.69c2.52,6.12,5.04,12.23,7.56,18.35c4,9.71,8,19.42,11.99,29.12c0.92,2.24,1.85,4.48,2.77,6.73
                            c0.62-0.62,1.23-1.23,1.85-1.85c-6.14-2.53-12.28-5.06-18.42-7.58c-9.74-4.01-19.47-8.02-29.21-12.03
                            c-2.24-0.92-4.47-1.84-6.71-2.76c0.35,0.35,0.7,0.7,1.05,1.05c-2.53-6.14-5.06-12.28-7.59-18.42
                            c-4.01-9.74-8.02-19.47-12.03-29.21c-0.92-2.24-1.84-4.47-2.76-6.71c-0.62,0.62-1.23,1.23-1.85,1.85
                            c8.85,3.64,17.7,7.29,26.56,10.93c1.26,0.52,2.52,1.04,3.79,1.56c1.79,0.74,2.55-2.16,0.8-2.89c-8.76-3.65-17.51-7.3-26.27-10.94
                            c-1.24-0.52-2.49-1.04-3.73-1.56c0,0.96,0,1.93,0,2.89c6.13-2.55,12.26-5.11,18.39-7.66c9.69-4.04,19.39-8.08,29.08-12.12
                            c1.52-0.63,3.04-1.27,4.56-1.9c0.41-0.17,1.87-0.5,2.11-0.88c0.13-0.21-0.73-0.22-0.59,0c0.09,0.14,0.62,0.26,0.77,0.32
                            c1.14,0.48,2.29,0.95,3.43,1.43c9.29,3.87,18.58,7.74,27.87,11.61c4.66,1.94,9.33,3.89,13.99,5.83c2.56,1.06,5.1,2.32,7.73,3.22
                            c0.12,0.04,0.24,0.1,0.37,0.15c0-0.96,0-1.93,0-2.89c-6.13,2.55-12.26,5.11-18.39,7.66c-9.72,4.05-19.44,8.1-29.16,12.15
                            c-2.23,0.93-4.46,1.86-6.7,2.79c-0.75,0.31-1.27,0.99-1.05,1.85C437.96,375.08,438.88,375.73,439.62,375.42z"/>
                        <path className="st4" d="M331.73,221.2c-0.09-0.21-0.18-0.43-0.27-0.64c-0.09-0.22-0.27-0.97-0.24-0.26
                            c0.04,0.86,0.24-0.35,0.24-0.48c0-0.13-0.03-0.29-0.06-0.42c-0.04-0.18-0.15-0.37-0.23-0.54c-0.44-1.06-0.88-2.11-1.32-3.17
                            c-1.71-4.1-3.42-8.2-5.13-12.3c-4.15-9.96-8.3-19.91-12.45-29.87c-0.98-2.35-1.96-4.71-2.94-7.06c-0.59-1.41-2.3-1.42-2.89,0
                            c-2.55,6.13-5.11,12.26-7.66,18.39c-4.07,9.77-8.14,19.54-12.21,29.31c-0.61,1.47-1.23,2.94-1.84,4.42
                            c-0.28,0.66-0.67,1.35-0.85,2.05c-0.18,0.67,0.05,1.07,0.29,1.65c0.46,1.11,0.92,2.22,1.39,3.33
                            c3.88,9.32,7.76,18.63,11.64,27.95c3.03,7.28,6.06,14.55,9.09,21.83c0.05,0.12,0.1,0.24,0.15,0.37c0.59,1.41,2.3,1.42,2.89,0
                            c2.52-6.05,5.04-12.1,7.56-18.15c4.02-9.65,8.04-19.29,12.06-28.94c0.93-2.22,1.85-4.44,2.78-6.66c0.74-1.78-2.16-2.56-2.89-0.8
                            c-2.52,6.05-5.04,12.1-7.56,18.15c-4.02,9.65-8.04,19.29-12.06,28.94c-0.93,2.22-1.85,4.44-2.78,6.66c0.96,0,1.93,0,2.89,0
                            c-2.55-6.13-5.11-12.26-7.66-18.39c-4.04-9.69-8.08-19.39-12.12-29.08c-0.63-1.52-1.27-3.04-1.9-4.56
                            c-0.17-0.41-0.5-1.87-0.88-2.11c-0.21-0.13-0.22,0.73,0,0.59c0.14-0.09,0.26-0.62,0.32-0.77c0.48-1.14,0.95-2.29,1.43-3.43
                            c3.87-9.29,7.74-18.58,11.61-27.87c1.94-4.66,3.89-9.32,5.83-13.99c1.06-2.55,2.32-5.1,3.22-7.72c0.04-0.12,0.1-0.24,0.15-0.37
                            c-0.96,0-1.93,0-2.89,0c2.65,6.36,5.3,12.72,7.96,19.09c4.02,9.65,8.04,19.3,12.07,28.94c0.57,1.36,1.13,2.72,1.7,4.07
                            c0.08,0.19,0.18,0.68,0.34,0.81c0.01,0.01,0.01,0.04,0.02,0.05c-0.23-0.11,0.26-1-0.1-0.55c-0.11,0.14-0.16,0.53-0.16,0.65
                            c0,0.57,0.37,1.16,0.58,1.68C329.57,223.75,332.47,222.98,331.73,221.2z"/>
                        <path className="st4" d="M292.05,415.13c-2.31,5.6-4.61,11.2-6.92,16.8c-0.56,1.36-1.12,2.71-1.68,4.07
                            c-0.25,0.6-0.72,1.35-0.72,2.02c0,0.98,0.79,2.16,1.15,3.04c5.41,12.97,10.81,25.95,16.22,38.92c1.74,4.18,3.49,8.37,5.23,12.55
                            c0.59,1.41,2.3,1.42,2.89,0c2.55-6.13,5.11-12.26,7.66-18.39c4.07-9.77,8.14-19.54,12.21-29.31c0.61-1.47,1.23-2.94,1.84-4.42
                            c0.28-0.66,0.67-1.35,0.85-2.05c0.18-0.67-0.05-1.07-0.29-1.65c-0.46-1.11-0.92-2.22-1.39-3.33
                            c-3.88-9.32-7.76-18.63-11.64-27.95c-3.03-7.28-6.06-14.55-9.09-21.83c-0.05-0.12-0.1-0.24-0.15-0.37c-0.59-1.41-2.3-1.42-2.89,0
                            c-3.88,9.3-7.75,18.6-11.63,27.9C293.16,412.47,292.6,413.8,292.05,415.13c-0.74,1.78,2.16,2.56,2.89,0.8
                            c3.88-9.3,7.75-18.6,11.63-27.9c0.55-1.33,1.11-2.66,1.66-3.99c-0.96,0-1.93,0-2.89,0c2.55,6.13,5.11,12.26,7.66,18.39
                            c4.04,9.69,8.08,19.39,12.12,29.08c0.63,1.52,1.27,3.04,1.9,4.56c0.17,0.41,0.5,1.87,0.88,2.11c0.21,0.13,0.22-0.73,0-0.59
                            c-0.14,0.09-0.26,0.62-0.32,0.77c-0.48,1.14-0.95,2.29-1.43,3.43c-3.87,9.29-7.74,18.58-11.61,27.87
                            c-1.94,4.66-3.89,9.32-5.83,13.99c-1.06,2.55-2.32,5.1-3.22,7.72c-0.04,0.12-0.1,0.24-0.15,0.37c0.96,0,1.93,0,2.89,0
                            c-4.33-10.4-8.67-20.79-13-31.19c-2.58-6.2-5.17-12.4-7.75-18.61c-0.54-1.29-0.95-2.96-1.72-4.13c-0.02-0.03-0.04-0.17-0.08-0.18
                            c1.23,0.57-0.25,1.05,0.05,0.69c0.08-0.1,0.11-0.28,0.16-0.39c0.43-1.03,0.85-2.07,1.28-3.1c2.59-6.3,5.18-12.59,7.78-18.89
                            C295.68,414.14,292.77,413.36,292.05,415.13z"/>
                        <path className="st4" d="M328.52,219.42c-0.05,0.13-0.11,0.26-0.16,0.39c-0.01,0.03-0.03,0.06-0.04,0.09c0,0.01-0.01,0.01-0.01,0.02
                            c0,0,0,0,0,0c-0.02,0.13-0.04,0.27-0.05,0.4c0.02,0.13,0.04,0.27,0.05,0.4c0.01,0.02,0.02,0.05,0.03,0.07
                            c0.13,0.3,0.25,0.61,0.38,0.91c0.04,0.1,0.08,0.2,0.12,0.3c0.05,0.12,0.1,0.24,0.15,0.36c0.09,0.17,0.22,0.32,0.39,0.42
                            c0.09,0.09,0.2,0.16,0.33,0.19c0.18,0.09,0.37,0.14,0.58,0.13c0.13-0.02,0.27-0.04,0.4-0.05c0.12-0.05,0.24-0.1,0.36-0.15
                            c0.17-0.09,0.32-0.22,0.42-0.39c0.13-0.14,0.22-0.31,0.27-0.51c0.05-0.13,0.11-0.26,0.16-0.39c0.01-0.03,0.03-0.06,0.04-0.09
                            c0-0.01,0.01-0.01,0.01-0.02c0,0,0,0,0,0c0.02-0.13,0.04-0.27,0.05-0.4c-0.02-0.13-0.04-0.27-0.05-0.4
                            c-0.01-0.02-0.02-0.05-0.03-0.07c-0.13-0.3-0.25-0.61-0.38-0.91c-0.04-0.1-0.08-0.2-0.12-0.3c-0.05-0.2-0.14-0.37-0.27-0.51
                            c-0.11-0.16-0.25-0.29-0.42-0.39c-0.17-0.11-0.36-0.17-0.56-0.18c-0.2-0.04-0.4-0.04-0.6,0.03c-0.36,0.12-0.71,0.34-0.9,0.69
                            C328.62,219.18,328.57,219.3,328.52,219.42c-0.07,0.27-0.07,0.53,0,0.8c0.18,0.43,0.35,0.85,0.53,1.28c0-0.27,0-0.53,0-0.8
                            c-0.07,0.17-0.14,0.33-0.21,0.5c0.96,0,1.93,0,2.89,0c-0.18-0.43-0.35-0.85-0.53-1.28c0,0.27,0,0.53,0,0.8
                            c0.07-0.17,0.14-0.33,0.21-0.5c0.06-0.2,0.07-0.4,0.03-0.6c-0.01-0.2-0.07-0.39-0.18-0.56c-0.09-0.17-0.22-0.32-0.39-0.42
                            c-0.14-0.13-0.31-0.22-0.51-0.27c-0.38-0.09-0.82-0.07-1.16,0.15c-0.1,0.08-0.2,0.16-0.3,0.23
                            C328.72,218.94,328.59,219.16,328.52,219.42z"/>
                    </g>
                </g>
            </g>
            <g id="Layer_3" fill={fill5} stroke="grey">
                <polygon className="st5" points="384.33,351.15 406.65,405.34 352.31,382.96 329.7,437.21 307.16,383.11 307.09,383.29 252.74,405.66 
                    275.12,351.32 220.87,328.72 275.12,306.11 275.3,306.18 252.98,251.99 307.33,274.37 329.93,220.12 352.47,274.22 352.54,274.04 
                    406.89,251.67 384.51,306.01 438.76,328.61 384.51,351.22 	"/>
            </g>
        </svg>

    </>)
}

export default StarBlanket;