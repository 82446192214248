import { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'

const EditEventWritten = ( {eventToEdit, setCurrentFunction, setCurrentSubject, setCurrentEdit, setEventToEdit} ) => {
    const [form, setForm] = useState ({});
    const [registration, setRegistration] = useState('');


    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
    }

    useEffect(() =>{
        setRegistration(eventToEdit.registration)
    },[]);

    useEffect(() =>{},[registration])
    

    const handleCheck = (e) => {
        e.preventDefault()
        console.log(registration)
        setRegistration = !registration
        setForm('registration', registration)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm(`are you sure you want to update ${eventToEdit.eventTitle}  in the database?`)) {
            
            const expressRes = await fetch(`https://testingrailway-production-d638.up.railway.app/api/update_event/${eventToEdit.eventId}`, {
                method: "PATCH",
                body: JSON.stringify({
                    form
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                })
                if (expressRes.status === 200) {
                    window.alert("event updated");
                    setCurrentFunction("");
                    setCurrentSubject("");
                    setCurrentEdit("");
                    setEventToEdit("");
                } else {
                    window.alert("Event could not be updated at this time :(")
                }
        }
    }
    

    return (<>
        <Form onSubmit={handleSubmit}>
        <Row className='mb-3'>
            <Col md>
                <Form.Label>Event Title</Form.Label>
                <Form.Control type="text" placeholder={eventToEdit.eventTitle} onChange={(e)=> setField('eventTitle', e.target.value) } />
        
            </Col>
            <Col>
                <Form.Label>Event SubTitle</Form.Label>
                <Form.Control type="text" placeholder={eventToEdit.eventSubTitle} onChange={(e)=> setField('eventSubTitle', e.target.value )} />
                
            </Col>
        </Row>
        <Row>
            <Form.Label>Date</Form.Label>
            <Form.Control type="text" placeholder={eventToEdit.eventDate} onChange={(e)=> setField( 'eventDate', e.target.value )} />
        </Row>
        <Row>
            <Form.Label>Time</Form.Label>
            <Form.Control type="text" placeholder={eventToEdit.eventTime} onChange={(e)=> setField( 'eventTime', e.target.value )} />
        </Row>
        <Row>
            <Form.Label>Location</Form.Label>
            <Form.Control type="text" placeholder={eventToEdit.location} onChange={(e)=> setField( 'location', e.target.value )} />
        </Row>
        <Row>
            <Form.Label>Write Up</Form.Label>
            <Form.Control
                    as="textarea"
                    placeholder={eventToEdit.eventWriteUp}
                    onChange={(e) => setField(  'eventWriteUp', e.target.value)}
                    style={{ height: '100px' }}
                    />
        </Row>
        <Row>
            <Form.Label>Requires Registration?</Form.Label>
            <Form.Select
                onChange={(e)=> setField( 'registration', e.target.value )}>
                <option value=''></option>
                <option value= {true} > event requires registration </option>
                <option value= {false} > event does not require registration </option>
            </Form.Select>
        </Row>
        <Row>
            <Form.Label>Registration Email</Form.Label>
            <Form.Control type="text" placeholder={eventToEdit.registrationEmail} onChange={(e)=> setField( 'registrationEmail', e.target.value )} />
        </Row>
        <Button type="submit">Submit</Button>
        </Form>
    </>)
}

const PhotoSection = styled.div`
    display:flex;
`

const PreviewImg = styled.img`
    height:300px;
`
const CurrentPhoto = styled.img`
    height:300px;
`

export default EditEventWritten;