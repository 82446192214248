import { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
// import { Document, Page } from 'react-pdf/dist/esm/index.webpack5';

const Article = () => {
    const articleId = useParams();
    const [loaded, setLoaded] = useState(false)
    const [articleData, setArticleData] = useState();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    console.log(articleId)
    useEffect(() => {
        fetch(`https://testingrailway-production-d638.up.railway.app/api/get_articles`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                setArticleData(data.data)
                setLoaded(true)
                })
        },[]);

    const onDocumentLoadSuccess = ({ numPages }) => {
            setNumPages(numPages);
        }

    return(<>
        {!loaded 
            ?<h1>loading...</h1>
            :<>
                            <Document file='https://res.cloudinary.com/dwczrztj8/image/upload/v1679872318/Diploma_full_stack_web_development_tuknei.pdf' onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                            </Document>
                            <p>
                            Page {pageNumber} of {numPages}
                            </p> 
            {articleData.map((article) => {
                    return(<>
                        {articleId.articleId === article.articleId 
                        && <>      
                            <Document file='https://res.cloudinary.com/dwczrztj8/image/upload/v1679872060/CV__2022_FINAL_uyugvy.pdf' onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <p>
                            Page {pageNumber} of {numPages}
                            </p> 
                        </>}
                    </>)
            })}
            
            </>
        }
    </>)
}

export default Article