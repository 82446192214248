import { useParams } from 'react-router-dom';
import Education from './Education/Education';
import ReachingHome from './ReachingHome/ReachingHome';
import Justice from './Justice/Justice';
import Health from './Health/Health';
import ChildServices from './ChildServices/ChildServices';
import Commerce from './Commerce/Commerce'
import Thanks from '../../Thanks';
import Transport from './Transport/Transport';
import { useState, useEffect } from 'react';
import ContentGeoLayout from './ContentGeoLayout';
import Connect from './Connect';
import Links from './Links';

const Department = ({mistymountain}) => {
    const {departmentId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [departmentData, setDepartmentData] = useState({});
    console.log(departmentId)

    useEffect(() => {
        fetch(`https://testingrailway-production-d638.up.railway.app/api/get_department/${departmentId}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                setDepartmentData(data.data)
                setLoaded(true)
                console.log(departmentData, "iiiii")})
        },[departmentId]);
    

    return (<>
        
        {departmentId === "education"&&<> <Education/> </>}
        {departmentId === "reachinghome"&& <> <ReachingHome/></>}
        {departmentId === "justice" && <> <Justice/></>}
        {departmentId === "health" && <><Health/></>}
        {departmentId === "childservices" && <> <ChildServices/></>}
        {departmentId === "transport" && <><Transport/></>}
        {departmentId === "commerce" && <><Commerce/></>}
        {loaded 
            ?<> <ContentGeoLayout departmentData={departmentData}/> 
            <Connect departmentId={departmentId} departmentData={departmentData}/>
        {departmentData.links[0].url.length > 0 && <Links departmentData={departmentData}/>}
        <Thanks></Thanks>
            <Thanks></Thanks>
            </>
            : <h1>loading</h1>}

    </>)

}

export default Department;