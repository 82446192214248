import { motion } from "framer-motion";



const StarBlanketStokeFill = ({stroke="#fff", fill="none", height, width, strokeWidth}) => {

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
          const delay = 1 + i * 0.5;
          return {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
              opacity: { delay, duration: 0.01 }
            }
          };
        }
      };

    return(
        <motion.svg 
            version="1.1" 
            id="Layer_8" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            x="0px" 
            y="0px"
	        viewBox="0 0 664.71 662.19" 
            xmlSpace="preserve"
            fill={fill}
            // stroke={stroke}
            height={height}
            width={width}
            initial="hidden"
            animate="visible"
            >

            <motion.path 
                className="st0"
                strokeWidth={strokeWidth}
                variants={draw}
                initial="hidden"
                animate="visible"
                stroke={stroke}
                d="M146.42,209.49l-22.38-54.34l54.34,22.38l22.38,54.34L146.42,209.49z M233.54,201.32l-22.38-54.34l-54.34-22.38
                    l22.38,54.34L233.54,201.32z M540.89,507.47l-22.38-54.34l-54.34-22.38l22.38,54.34L540.89,507.47z M508.56,539.15l-22.38-54.34
                    l-54.34-22.38l22.38,54.34L508.56,539.15z M265.99,61.67l-22.6,54.25l22.6,54.25l22.6-54.25L265.99,61.67z M189.05,93.54
                    l22.38,54.34l54.34,22.38l-22.38-54.34L189.05,93.54z M400.19,59.2l-22.6,54.25l22.6,54.25l22.6-54.25L400.19,59.2z M476.68,90.46
                    l-54.34,22.38l-22.38,54.34l54.34-22.38L476.68,90.46z M605.21,263.73l-54.25-22.6l-54.25,22.6l54.25,22.6L605.21,263.73z
                    M573.34,186.78L519,209.16l-22.38,54.34l54.34-22.38L573.34,186.78z M399.73,603.67l22.6-54.25l-22.6-54.25l-22.6,54.25
                    L399.73,603.67z M476.68,571.79l-22.38-54.34l-54.34-22.38l22.38,54.34L476.68,571.79z M263.97,603.24l22.6-54.25l-22.6-54.25
                    l-22.6,54.25L263.97,603.24z M187.01,571.7l54.34-22.38l22.38-54.34l-54.34,22.38L187.01,571.7z M91.81,186.35l22.38,54.34
                    l54.34,22.38l-22.38-54.34L91.81,186.35z M60.1,262.9l54.25,22.6l54.25-22.6l-54.25-22.6L60.1,262.9z M62.59,308.62l54.25,22.6
                    l54.25-22.6l-54.25-22.6L62.59,308.62z M157.41,538.02l54.34-22.38l22.38-54.34l-54.34,22.38L157.41,538.02z M602.99,353.98
                    l-54.25-22.6l-54.25,22.6l54.25,22.6L602.99,353.98z M61.62,353.8l54.25,22.6l54.25-22.6l-54.25-22.6L61.62,353.8z M541.6,153.85
                    l-54.34,22.38l-22.38,54.34l54.34-22.38L541.6,153.85z M355,603.19l22.6-54.25L355,494.7l-22.6,54.25L355,603.19z M309.49,603.62
                    l22.6-54.25l-22.6-54.25l-22.6,54.25L309.49,603.62z M354.72,58.42l-22.6,54.25l22.6,54.25l22.6-54.25L354.72,58.42z M310.25,59.2
                    l-22.6,54.25l22.6,54.25l22.6-54.25L310.25,59.2z M508.56,123.27l-54.34,22.38l-22.38,54.34l54.34-22.38L508.56,123.27z
                    M605.59,308.94l-54.25-22.6l-54.25,22.6l54.25,22.6L605.59,308.94z M571.84,476.34L549.46,422l-54.34-22.38l22.38,54.34
                    L571.84,476.34z M603.95,399.62l-54.25-22.6l-54.25,22.6l54.25,22.6L603.95,399.62z M124.05,506.29l54.34-22.38l22.38-54.34
                    l-54.34,22.38L124.05,506.29z M59.31,398.98l54.25,22.6l54.25-22.6l-54.25-22.6L59.31,398.98z M91.18,475.92l54.34-22.38
                    l22.38-54.34l-54.34,22.38L91.18,475.92z M331.97,548.55l22.6-54.25l-22.6-54.25l-22.6,54.25L331.97,548.55z M333.09,222.91
                    l22.6-54.25l-22.6-54.25l-22.6,54.25L333.09,222.91z M486.21,177.87l-54.34,22.38l-22.38,54.34l54.34-22.38L486.21,177.87z
                    M255.58,255.64L233.2,201.3l-54.34-22.38l22.38,54.34L255.58,255.64z M486.54,485.21l-22.38-54.34l-54.34-22.38l22.38,54.34
                    L486.54,485.21z M288.87,116.12l-22.6,54.25l22.6,54.25l22.6-54.25L288.87,116.12z M211.93,147.99l22.38,54.34l54.34,22.38
                    l-22.38-54.34L211.93,147.99z M377.77,114.15l-22.6,54.25l22.6,54.25l22.6-54.25L377.77,114.15z M454.27,145.4l-54.34,22.38
                    l-22.38,54.34l54.34-22.38L454.27,145.4z M377.18,548.6l22.6-54.25l-22.6-54.25l-22.6,54.25L377.18,548.6z M454.12,516.73
                    l-22.38-54.34l-54.34-22.38l22.38,54.34L454.12,516.73z M287.26,548.98l22.12-53.1l-22.12-53.1l-22.12,53.1L287.26,548.98z
                    M211.93,518.1l53.19-21.9l21.9-53.19l-53.19,21.9L211.93,518.1z M114.34,376.15l54.25,22.6l54.25-22.6l-54.25-22.6L114.34,376.15z
                    M146.22,453.09l54.34-22.38l22.38-54.34l-54.34,22.38L146.22,453.09z M146.52,209.43l22.38,54.34l54.34,22.38l-22.38-54.34
                    L146.52,209.43z M114.8,285.99l54.25,22.6l54.25-22.6l-54.25-22.6L114.8,285.99z M518.81,453.47l-22.38-54.34l-54.34-22.38
                    l22.38,54.34L518.81,453.47z M550.92,376.75l-54.25-22.6l-54.25,22.6l54.25,22.6L550.92,376.75z M550.58,286.02l-54.25-22.6
                    l-54.25,22.6l54.25,22.6L550.58,286.02z M518.7,209.07l-54.34,22.38l-22.38,54.34l54.34-22.38L518.7,209.07z M550.75,331.54
                    l-54.25-22.6l-54.25,22.6l54.25,22.6L550.75,331.54z M114.8,330.94l54.25,22.6l54.25-22.6l-54.25-22.6L114.8,330.94z M178.07,485.45
                    l54.34-22.38l22.38-54.34l-54.34,22.38L178.07,485.45z M178.86,178.92l-22.38-54.34l-54.34-22.38l22.38,54.34L178.86,178.92z
                    M563.67,562.55l-22.38-54.34l-54.34-22.38l22.38,54.34L563.67,562.55z M531.12,594.22l-22.38-54.34L454.4,517.5l22.38,54.34
                    L531.12,594.22z M421.01,657.96l22.6-54.25l-22.6-54.25l-22.6,54.25L421.01,657.96z M497.95,626.09l-22.38-54.34l-54.34-22.38
                    l22.38,54.34L497.95,626.09z M242.01,658.29l22.6-54.25l-22.6-54.25l-22.6,54.25L242.01,658.29z M165.05,626.75l54.34-22.38
                    l22.38-54.34l-54.34,22.38L165.05,626.75z M422.55,4.17l-22.6,54.25l22.6,54.25l22.6-54.25L422.55,4.17z M499.05,35.43L444.7,57.8
                    l-22.38,54.34l54.34-22.38L499.05,35.43z M532.86,68.86l-54.34,22.38l-22.38,54.34l54.34-22.38L532.86,68.86z M660.81,241.13
                    l-54.25-22.6l-54.25,22.6l54.25,22.6L660.81,241.13z M628.94,164.18l-54.34,22.38l-22.38,54.34l54.34-22.38L628.94,164.18z
                    M242.97,7.9l-22.6,54.25l22.6,54.25l22.6-54.25L242.97,7.9z M166.02,39.78l22.38,54.34l54.34,22.38l-22.38-54.34L166.02,39.78z
                    M131.75,68.86l22.38,54.34l54.34,22.38L186.1,91.24L131.75,68.86z M144.56,206.75l-22.38-54.34l-54.34-22.38l22.38,54.34
                    L144.56,206.75z M35.61,164.54l22.38,54.34l54.34,22.38l-22.38-54.34L35.61,164.54z M3.9,241.1l54.25,22.6l54.25-22.6l-54.25-22.6
                    L3.9,241.1z M3.9,285.51l54.25,22.6l54.25-22.6l-54.25-22.6L3.9,285.51z M168.64,353.8l54.25,22.6l54.25-22.6l-54.25-22.6
                    L168.64,353.8z M200.51,430.75l54.34-22.38l22.38-54.34l-54.34,22.38L200.51,430.75z M200.76,231.87l22.38,54.34l54.34,22.38
                    l-22.38-54.34L200.76,231.87z M169.05,308.43l54.25,22.6l54.25-22.6l-54.25-22.6L169.05,308.43z M354.96,168.67l-22.6,54.25
                    l22.6,54.25l22.6-54.25L354.96,168.67z M431.92,200.21l-54.34,22.38l-22.38,54.34l54.34-22.38L431.92,200.21z M496.04,308.34
                    l-54.25-22.6l-54.25,22.6l54.25,22.6L496.04,308.34z M464.17,231.39l-54.34,22.38l-22.38,54.34l54.34-22.38L464.17,231.39z
                    M354.9,494.3l22.6-54.25l-22.6-54.25l-22.6,54.25L354.9,494.3z M431.84,462.43l-22.38-54.34l-54.34-22.38l22.38,54.34
                    L431.84,462.43z M309.38,494.73l22.6-54.25l-22.6-54.25l-22.6,54.25L309.38,494.73z M232.42,463.18l54.34-22.38l22.38-54.34
                    l-54.34,22.38L232.42,463.18z M463.96,430.7l-22.38-54.34l-54.34-22.38l22.38,54.34L463.96,430.7z M496.08,353.98l-54.25-22.6
                    l-54.25,22.6l54.25,22.6L496.08,353.98z M310.49,169.45l-22.6,54.25l22.6,54.25l22.6-54.25L310.49,169.45z M233.54,201.32
                    l22.38,54.34l54.34,22.38l-22.38-54.34L233.54,201.32z M103.06,560.28l54.34-22.38l22.38-54.34l-54.34,22.38L103.06,560.28z
                    M134.81,592.27l54.34-22.38l22.38-54.34l-54.34,22.38L134.81,592.27z M657.84,376.75l-54.25-22.6l-54.25,22.6l54.25,22.6
                    L657.84,376.75z M657.67,331.54l-54.25-22.6l-54.25,22.6l54.25,22.6L657.67,331.54z M7.32,376.15l54.25,22.6l54.25-22.6l-54.25-22.6
                    L7.32,376.15z M7.78,330.94l54.25,22.6l54.25-22.6l-54.25-22.6L7.78,330.94z M563.64,100.33L509.3,122.7l-22.38,54.34l54.34-22.38
                    L563.64,100.33z M596.13,131.53l-54.34,22.38l-22.38,54.34l54.34-22.38L596.13,131.53z M332.08,657.44l22.6-54.25l-22.6-54.25
                    l-22.6,54.25L332.08,657.44z M377.28,657.49l22.6-54.25l-22.6-54.25l-22.6,54.25L377.28,657.49z M286.88,657.87l22.6-54.25
                    l-22.6-54.25l-22.6,54.25L286.88,657.87z M332.86,112.67l22.6-54.25l-22.6-54.25l-22.6,54.25L332.86,112.67z M288.64,5.87
                    l-22.6,54.25l22.6,54.25l22.6-54.25L288.64,5.87z M377.54,3.9l-22.6,54.25l22.6,54.25l22.6-54.25L377.54,3.9z M593.92,530.34
                    l-22.38-54.34l-54.34-22.38l22.38,54.34L593.92,530.34z M657.24,285.51l-54.25-22.6l-54.25,22.6l54.25,22.6L657.24,285.51z
                    M69.37,528.49l54.34-22.38l22.38-54.34l-54.34,22.38L69.37,528.49z M3.9,419.68l54.25,22.6l54.25-22.6l-54.25-22.6L3.9,419.68z
                    M35.77,496.62l54.34-22.38l22.38-54.34l-54.34,22.38L35.77,496.62z M627.89,498.66l-22.38-54.34l-54.34-22.38l22.38,54.34
                    L627.89,498.66z M660,421.93l-54.25-22.6l-54.25,22.6l54.25,22.6L660,421.93z M332.53,222.72l-22.6,54.25l22.6,54.25l22.6-54.25
                    L332.53,222.72z M255.59,254.59l22.38,54.34l54.34,22.38l-22.38-54.34L255.59,254.59z M332.31,439.8l22.6-54.25l-22.6-54.25
                    l-22.6,54.25L332.31,439.8z M409.25,407.93l-22.38-54.34l-54.34-22.38l22.38,54.34L409.25,407.93z M441.36,331.21l-54.25-22.6
                    l-54.25,22.6l54.25,22.6L441.36,331.21z M409.49,254.26l-54.34,22.38l-22.38,54.34l54.34-22.38L409.49,254.26z M223.48,331.31
                    l54.25,22.6l54.25-22.6l-54.25-22.6L223.48,331.31z M255.35,408.26l54.34-22.38l22.38-54.34l-54.34,22.38L255.35,408.26z"/>
        </motion.svg>

    )
}

export default StarBlanketStokeFill;